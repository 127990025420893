import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import FormInput from '../../../components/DataForm/FormInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import axios from '../../../axios';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import DatePicker from '../../../components/DatePicker/DatePicker';
import TitleHeader from '../../../components/TitleHeader/TitleHeader';
import JobWizardSelectEmployee from './JobWizardSelectEmployee';
import { Services } from '../../Service/Services';
import swal from 'sweetalert';

const $ = window.$;

class JobWizard extends Component {
	constructor(props) {
		super(props);

		this.element = React.createRef();
	}

	state = {
		employee_list: [],
		employee_list_outsource: [],
		car_list_id: [],
		car_list_id_outsource: [],
		datasource: {
			customer_datasource: [],
			province_datasource: [],
			expense_datasource: [],
			car_datasource: [],
		},

		transaction_date_start: '',
		transaction_date_end: '',
		customer_id: '',
		customer_name: '',
		province_id: '',
		contact_customer_id: '',
		loading: true,
		step: 1,
		redirect: false,
		errors: [],
	}

	datepickerCallback = e => {
		const { name, value } = e.target;

		this.setState({ [name]: value });
	}

	selectEmployee = (item, is_outsource) => {
		if (is_outsource === false) {
			const employee_list = this.state.employee_list;
			const car_list_id = this.state.car_list_id;

			const index = employee_list.findIndex(i => i.key === item.key);

			if (index >= 0) {
				// remove
				employee_list.splice(index, 1);
				car_list_id.splice(index, 1);
			} else {
				// add
				employee_list.push(item);
				car_list_id.push(null);
			}

			this.setState({ employee_list, car_list_id });
		} else {
			const employee_list_outsource = this.state.employee_list_outsource;
			const car_list_id_outsource = this.state.car_list_id_outsource;

			const index = employee_list_outsource.findIndex(i => i.key === item.key);

			if (index >= 0) {
				// remove
				employee_list_outsource.splice(index, 1);
				car_list_id_outsource.splice(index, 1);
			} else {
				// add
				employee_list_outsource.push(item);
				car_list_id_outsource.push('');
			}

			this.setState({ employee_list_outsource, car_list_id_outsource });
		}
	}

	onChange = (event) => {
		const target_name = event.target.name;
		const value = event.target.value;

		var new_items = { [target_name]: value };

		if (target_name === 'customer_id') {
			const contact_customer_datasource = this.getContactCustomerDatasource(value)

			new_items.contact_customer_id = '';
			new_items.datasource = this.state.datasource;
			new_items.datasource.contact_customer_datasource = contact_customer_datasource;
		}
		this.setState(new_items);
	}

	getCustomerName() {
		const customer_id = this.state.customer_id;

		const customer = this.state.datasource.customer_datasource.find(f => f.key == customer_id);

		if (customer !== undefined) {
			return customer.label;
		}

		return '';
	}

	getContactCustomerDatasource = (customer_id, customer_datasource = null) => {

		customer_datasource = (customer_datasource === null ? this.state.datasource.customer_datasource : customer_datasource);
		const customers = customer_datasource.filter(f => f.key == customer_id);

		if (customers.length > 0) {

			const contact_customer = customers[0].contact_customers;

			return contact_customer;
		}

		return [];
	}

	onChangeCar = (event, index, is_outsource) => {
		const value = event.target.value;

		if (is_outsource === false) {
			const car_list_id = this.state.car_list_id;
			car_list_id[index] = value;
			this.setState({ car_list_id });
		} else {
			const car_list_id_outsource = this.state.car_list_id_outsource;
			car_list_id_outsource[index] = value;
			this.setState({ car_list_id_outsource });
		}
	}
	componentDidMount = () => {
		this.clearForm();
	}


	step = index => {
		if ((index === -1) || this.validateStep()) {
			const step = this.state.step + index;
			const errors = [];
			let loading = false;

			if (step === 2) {
				// loading = true;
			}

			this.setState({ step, errors, loading });
		}
	}

	validateStep = () => {
		const { step, transaction_date_start, customer_id, employee_list, employee_list_outsource } = this.state;
		let errors = [];
		if (step === 1) {
			if (transaction_date_start === '' || transaction_date_start === null) {
				errors.push("กรุณาเลือกวันที่เริ่มต้น");
			}

			if (customer_id === '' || customer_id === null) {
				errors.push("กรุณาเลือกลูกค้า");
			} else {
				const params = {
					transaction_start_date: this.state.transaction_date_start,
					transaction_end_date: this.state.transaction_date_end
				}
				axios.post('/lov/cars', params).then(res => {
					const car_datasource = res.data;
					this.setState({
						datasource: {
							...this.state.datasource,
							car_datasource,
						},
					});
				});
			}
		}
		else if (step === 2) {
			if (employee_list.length === 0 && employee_list_outsource.length === 0) {
				errors.push("กรุณาเลือกข้อมูลพนักงาน");
			}
		} else if (step === 3) {
			const car_list_id = this.state.car_list_id;
			const car_list_id_outsource = this.state.car_list_id_outsource;

			// filter duplicate
			let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);
			const check_car_id_repeat = findDuplicates(car_list_id);
			// const check_car_id_outsource_repeat = findDuplicates(car_list_id_outsource);


			var cli = false;
			var clio = false;
			car_list_id.forEach(function (item) { if (!item) { cli = true; } });
			car_list_id_outsource.forEach(function (item) { if (!item) { clio = true; } });
			if (cli) { errors.push("กรุณาเลือกรถทั่วไป"); }
			if (clio) { errors.push("กรุณาระบุรถร่วมบริการ"); }

			if (errors.length === 0) {
				if (check_car_id_repeat.length !== 0) {
					errors.push("ข้อมูลรถที่เลือกซ้ำ");
				}
				// else {
				// 	car_list_id.forEach(function (item) {
				// 		car_list_id_outsource.forEach((ele) => {
				// 			if (ele === item && errors.length === 0) {
				// 				errors.push("ข้อมูลรถที่เลือกซ้ำ");
				// 			}
				// 		})
				// 	});
				// }
			}

		}

		if (errors.length > 0) {
			this.setState({ errors });
			return false;
		}

		return true;
	}

	save = () => {
		this.saveToDB().then(res => {
			const redirect_to_edit = ('/transaction/job/' + res.data.id + '/edit');

			this.setState({ redirect: true, redirect_to_edit });
		});
	}

	saveToDB() {
		var url = '/transaction/job';
		var job_details = [];
		var transaction_date_start = this.state.transaction_date_start;
		var transaction_date_end = this.state.transaction_date_end;

		if (transaction_date_start) {
			// var employees = [];

			const employee_list = this.state.employee_list.concat(this.state.employee_list_outsource);
			// const car_list = this.state.car_list_id.concat(this.state.car_list_id_outsource);

			const car_list = employee_list.map((item, index) => {
				return {
					car_id: this.state.car_list_id[index] ? this.state.car_list_id[index] : null,
				}
			});
			var count = 0;
			const car_text = car_list.map((item, index) => {
				var text = null;

				if (!item.car_id) {
					text = this.state.car_list_id_outsource[count++]
				}
				return {
					car_id: text,
				}
			});
			const employees = employee_list.map((item, index) => {
				return {
					employee_id: item.key,
					car_id: car_list[index].car_id,
					car_text: car_text[index].car_id,
				}
			});

			const province_id = this.state.province_id;

			transaction_date_start = Services.convertToDate(transaction_date_start);
			job_details.push({ transaction_date: transaction_date_start.format('DD/MM/YYYY'), province_id, employees });

			if (transaction_date_end) {
				transaction_date_end = Services.convertToDate(transaction_date_end);

				const day_diff = transaction_date_end.diff(transaction_date_start, 'days');

				if (day_diff > 0) {
					for (var i = 0; i < day_diff; i++) {
						transaction_date_start.add(1, 'days');

						const transaction_date = transaction_date_start.format('DD/MM/YYYY');

						job_details.push({
							transaction_date,
							employees,
							province_id,
						});
					}
				}
			}
		}

		var params = {
			customer_id: this.state.customer_id,
			province_id: this.state.province_id,
			contact_customer_id: this.state.contact_customer_id,
			job_details: job_details,
		}

		return axios.post(url, params);
	}

	saveAndPrint = () => {
		this.saveToDB().then(res => {
			const id = res.data.id;
			const url = axios.defaults.baseURL + '/report/car/' + id;

			swal({
				title: "Success",
				text: 'บันทึกข้อมูลเสร็จสิ้น',
				icon: "success",
				button: "OK",
			}).then(() => {
				this.clearForm();
			});
		});
	}

	clearForm = () => {
		if (this.state.loading === false) {
			this.setState({ loading: true });
		}

		const newState = { ...this.state };

		newState.employee_list = [];
		newState.employee_list_outsource = [];
		newState.car_list_id = [];
		newState.car_list_id_outsource = [];
		newState.transaction_date_start = '';
		newState.transaction_date_end = '';
		newState.customer_id = '';
		newState.customer_name = '';
		newState.province_id = '';
		newState.contact_customer_id = '';
		newState.loading = false;
		newState.step = 1;
		newState.redirect = false;
		newState.errors = [];

		const params = {
			tables: [
				{ name: 'customer' },
				{ name: 'province' },
				{ name: 'expense' },
				// { name: 'car' },
			]
		}

		axios.post('/dropdown/datasource', params).then(res => {
			const customer_datasource = res.data.customer;
			const province_datasource = res.data.province;
			const expense_datasource = res.data.expense;
			// const car_datasource = res.data.car;
			this.setState({
				...newState,
				datasource: {
					customer_datasource: customer_datasource,
					province_datasource: province_datasource,
					expense_datasource: expense_datasource,
				},
				loading: false,
			});
		});
	}

	getValue = (value, index) => {
		const car_list_id_outsource = this.state.car_list_id_outsource;
		car_list_id_outsource[index] = value.car_id;
		this.setState({ car_list_id_outsource });
	}

	render = () => {
		const redirect = this.state.redirect;
		if (redirect) {
			return <Redirect to={this.state.redirect_to_edit} />;
		}

		var buttons;
		if (this.state.step === 1) {
			buttons = (
				<div>
					<button className='btn btn-secondary disabled mr-2' onClick={() => this.step(-1)}> <i className="fa fa-chevron-left"></i> Previous</button>
					<button className='btn btn-secondary' onClick={() => this.step(1)}>Next <i className="fa fa-chevron-right"></i></button>
				</div>
			)
		} else if (this.state.step === 2) {
			buttons = (
				<div>
					<button className='btn btn-secondary mr-2' onClick={() => this.step(-1)}> <i className="fa fa-chevron-left"></i> Previous</button>
					<button className='btn btn-secondary' onClick={() => this.step(1)}>Next <i className="fa fa-chevron-right"></i></button>
				</div>
			)
		} else if (this.state.step === 3) {
			buttons = (
				<div>
					<button className='btn btn-secondary mr-2' onClick={() => this.step(-1)}> <i className="fa fa-chevron-left"></i> Previous</button>
					<button className='btn btn-secondary' onClick={() => this.step(1)}>Next <i className="fa fa-chevron-right"></i></button>
				</div>
			)
		} else {
			buttons = (
				<div>
					<button className='btn btn-secondary mr-2' onClick={() => this.step(-1)}> <i className="fa fa-chevron-left"></i> Previous</button>
					<button className='btn btn-secondary disabled' onClick={() => this.step(1)}>Next <i className="fa fa-chevron-right"></i></button>
				</div>
			)
		}

		var active_step1 = (this.state.step === 1);
		var active_step2 = (this.state.step === 2);
		var active_step3 = (this.state.step === 3);
		var active_step4 = (this.state.step === 4);

		const customer_name = this.getCustomerName();
		let transaction_date = this.state.transaction_date_start;

		if (this.state.transaction_date_end) {
			transaction_date += (' - ' + this.state.transaction_date_end);
		}

		const employee_not_outsource = this.state.employee_list.map((item, index) => {
			return (
				<li key={index}>{item.values.name} <i className='fa fa-times text-danger' onClick={() => this.selectEmployee(item, false)}></i></li>
			);
		});

		const employee_outsource = this.state.employee_list_outsource.map((item, index) => {
			return (
				<li key={index}>{item.values.name} <i className='fa fa-times text-danger' onClick={() => this.selectEmployee(item, true)}></i></li>
			);
		});

		const employee_not_outsource_car = this.state.employee_list.map((item, index) => {
			return (
				<tr key={index}>
					<td><label htmlFor={('select_' + item.key)}>{item.values.name}</label></td>
					<td className='text-center'>{item.values.telephone}</td>
					<td className='text-center'>
						<FormInput field={{ value: this.state.car_list_id[index], name: "car_id", type: "ddl", datasource: this.state.datasource.car_datasource }} onChange={(event) => this.onChangeCar(event, index, false)}></FormInput>
					</td>
				</tr >
			);
		});

		const employee_outsource_car = this.state.employee_list_outsource.map((item, index) => {
			return (
				<tr key={index}>
					<td><label htmlFor={('select_' + item.key)}>{item.values.name}</label></td>
					<td className='text-center'>{item.values.telephone}</td>
					<td className='text-center'>
						{/* <FormInput field={{ value: this.state.car_list_id_outsource[index], name: "car_id", type: "ddl", datasource: this.state.datasource.car_datasource }} onChange={(event) => this.onChangeCar(event, index, true)}></FormInput> */}

						<FormInput field={{ name: 'car_id', value: this.state.car_list_id_outsource[index] }} getValue={(event) => this.getValue(event, index)}></FormInput>
					</td>
				</tr>
			);
		});


		return (
			<div>
				<TitleHeader program_name='สร้างใบงาน' program_type='Transaction'>
				</TitleHeader>
				<BoxContainer loading={this.state.loading} errors={this.state.errors}>
					<div className='text-center'>
						<div className="row">
							<div className="col-md-6 offset-md-3 col-12 text-center">
								{buttons}
							</div>
						</div>
					</div>
					<ul className="nav nav-pills my-3" id="pills-tab" role="tablist">
						<li className="nav-item">
							<a className={active_step1 ? ("nav-link active") : "nav-link disabled"} id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">1. เลือกลูกค้า</a>
						</li>
						<li className="nav-item">
							<a className={active_step2 ? ("nav-link active") : "nav-link disabled"} id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">2. เลือกพนักงาน</a>
						</li>
						<li className="nav-item">
							<a className={active_step3 ? ("nav-link active") : "nav-link disabled"} id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">3. เลือกรถ</a>
						</li>
						<li className="nav-item">
							<a className={active_step4 ? ("nav-link active") : "nav-link disabled"} id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">4. เสร็จสิ้น</a>
						</li>
					</ul>
					<div className="tab-content" id="pills-tabContent">
						<div className={active_step1 ? ("tab-pane fade show active") : "tab-pane fade"} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
							<div className="jumbotron bg-light pt-3">
								<FormInput field={{ value: this.state.customer_id, name: "customer_id", label: "ลูกค้า", type: "ddl", datasource: this.state.datasource.customer_datasource, required: true }} onChange={this.onChange}></FormInput>
								<FormInput field={{ value: this.state.contact_customer_id, name: "contact_customer_id", label: "ผู้ติดต่อ", type: "ddl", datasource: this.state.datasource.contact_customer_datasource, allow_new_item: true }} onChange={this.onChange}></FormInput>
								<FormInput field={{ value: this.state.province_id, name: "province_id", label: "จังหวัด", type: "ddl", datasource: this.state.datasource.province_datasource }} onChange={this.onChange}></FormInput>
								<div className='form-group'>
									<label className="col-form-label field-required">วันที่เริ่มต้น - วันที่สิ้นสุด</label>
									<DatePicker name='transaction_date' value={[this.state.transaction_date_start, this.state.transaction_date_end]} isRange={true} datepickerCallback={this.datepickerCallback}></DatePicker>
								</div>
							</div>
						</div>
						<div className={active_step2 ? ("tab-pane fade show active") : "tab-pane fade"} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
							<div className="jumbotron bg-light">
								<h1>{customer_name}</h1>
								<h3>{transaction_date}</h3>
								<div className="row">
									<div className='col-12 col-md-9'>
										<div className="tabs-container">
											<ul className="nav nav-tabs" role="tablist">
												<li><a className="nav-link active" data-toggle="tab" href="#tab-1">รถทั่วไป</a></li>
												<li><a className="nav-link" data-toggle="tab" href="#tab-2">รถร่วมบริการ</a></li>
											</ul>
											<div className="tab-content">
												<div role="tabpanel" id="tab-1" className="tab-pane active">
													<div className="panel-body">
														<JobWizardSelectEmployee
															value={this.state.employee_list}
															transaction_start_date={this.state.transaction_date_start}
															transaction_end_date={this.state.transaction_date_end}
															onSelect={this.selectEmployee}
															is_outsource={false}
															onLoading={(enable) => { this.setState({ loading: enable }); }}
															reload={active_step2}
														></JobWizardSelectEmployee>
													</div>
												</div>
												<div role="tabpanel" id="tab-2" className="tab-pane">
													<div className="panel-body">
														<JobWizardSelectEmployee
															value={this.state.employee_list_outsource}
															transaction_start_date={this.state.transaction_date_start}
															transaction_end_date={this.state.transaction_date_end}
															is_outsource={true}
															onLoading={(enable) => { this.setState({ loading: enable }); }}
															onSelect={this.selectEmployee}
															reload={active_step2}
														></JobWizardSelectEmployee>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='col-12 col-md-3'>
										<h3>รถท่ัวไป</h3>
										<ul>{employee_not_outsource}</ul>
										<hr />
										<h3>รถร่วมบริการ</h3>
										<ul>{employee_outsource}</ul>
									</div>
								</div>
							</div>
						</div>
						<div className={active_step3 ? ("tab-pane fade show active") : "tab-pane fade"} id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
							<div className=''>
								<h3>รถท่ัวไป</h3>
								<table className='table table-striped'>
									<thead>
										<tr>
											<th className="text-center">ชื่อ</th>
											<th className="text-center">เบอร์</th>
											<th className="text-center">รถ</th>
										</tr>
									</thead>
									<tbody>
										{employee_not_outsource_car}
									</tbody>
								</table>
								<h3>รถร่วมบริการ</h3>
								<table className='table table-striped'>
									<thead>
										<tr>
											<th className="text-center">ชื่อ</th>
											<th className="text-center">เบอร์</th>
											<th className="text-center">รถ</th>
										</tr>
									</thead>
									<tbody>
										{employee_outsource_car}
									</tbody>
								</table>
							</div>
						</div>
						<div className={active_step4 ? ("tab-pane fade show active") : "tab-pane fade"} id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
							<div className="jumbotron bg-light">
								<button className='btn btn-secondary w-100 btn-lg mb-3' onClick={this.save}>บันทึกและแก้ไข <i className="fa fa-floppy-o"></i></button>
								<button className='btn btn-secondary w-100 btn-lg' onClick={this.saveAndPrint}>บันทึกและสร้างใบงานใหม่ <i className="fa fa-print"></i></button>
							</div>
						</div>
					</div>
					<div className='text-center'>
						<div className="row">
							<div className="col-md-6 offset-md-3 col-12 text-center">
								{buttons}
							</div>
						</div>
					</div>
				</BoxContainer>
			</div >
		);
	}

}

export default JobWizard;