import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { authenticationService } from '../../pages/Authentication/Service/authentication.service';
import avatar from '../../assets/images/user.png';
// import { history } from '../../pages/Authentication/_helpers/';
// const $ = window.$;
class Navbar extends Component {
    state = {
        menues: [
            {
                label: 'Dashboard',
                icon_class: 'fa fa-dashboard',
                link: '/',
            },
            {
                label: 'เพิ่มใบงาน',
                icon_class: 'fa fa-plus-circle',
                link: '/transaction/job_wizard',
            },
            {
                label: 'เพิ่มใบแจ้งหนี้',
                icon_class: 'fa fa-plus-circle',
                link: '/transaction/invoice',
            },
            {
                label: 'เพิ่มใบเสร็จ',
                icon_class: 'fa fa-plus-circle',
                link: '/transaction/receipt',
            },
            {
                label: 'สถานะใบงาน',
                icon_class: 'fa fa-tasks',
                link: '/transaction/job',
            },
            {
                label: 'ข้อมูลคนขับรถ',
                icon_class: 'fa fa-vcard-o',
                link: '/report/job',
            },
            {
                label: 'Master',
                icon_class: 'fa fa-cogs',
                child: [
                    { label: 'ข้อมูลผู้ใช้งาน', link: '/master/user' },
                    { label: 'ข้อมูลพนักงาน', link: '/master/employee' },
                    { label: 'ข้อมูลลูกค้า', link: '/master/customer' },
                    { label: 'ตั้งค่าทั่วไป', link: '/master/setting' },
                    { label: 'ข้อมูลรถ', link: '/master/car' },
                ]
            },
            {
                label: 'Transaction',
                icon_class: 'fa fa-usd',
                child: [
                    { label: 'ใบงาน', link: '/transaction/job' },
                    { label: 'ใบแจ้งหนี้', link: '/transaction/invoice' },
                    { label: 'ใบรับเงิน', link: '/transaction/expense' },
                    { label: 'ใบเสร็จรับเงิน', link: '/transaction/receipt' },
                ]
            },
            {
                label: 'Report',
                icon_class: 'fa fa-book',
                child: [
                    { label: 'รายงานภาษีขาย', link: '/report/tax' },
                    { label: 'รายงานคนขับ', link: '/report/job' },
                    { label: 'รายงานใบงานที่ยังไม่ได้ออกใบแจ้งหนี้', link: '/report/job_invoice' },
                    { label: 'รายงานใบแจ้งหนี้ที่ยังไม่ได้ออกใบเสร็จ', link: '/report/invoice_receipt' },
                ]
            },
        ],
        location: window.location.pathname
    }
    logout() {
        authenticationService.logout();
        // history.push('/login');
        // const { from } = this.props.location.state || { from: { pathname: "/" } };
        // this.props.history.push(from);
    }
    Clicklink = (e) => {
        this.setState({ location: window.location.pathname });
    }
    render() {
        var menues = this.state.menues.map((item, index) => {
            if (item.child) {
                var active_item = false;
                var child = item.child.map((item, index) => {
                    const link_class = [];
                    if (item.link === this.state.location) {
                        link_class.push('active');
                        active_item = true;
                    }
                    return (<li key={index} onClick={this.Clicklink.bind(index)} className={link_class.join(' ')}>
                        <Link to={item.link}>{item.label}</Link>
                    </li>)
                });

                return (
                    <li key={index} className={active_item ? 'active' : ''}>
                        <Link to=' '><i className={item.icon_class}></i> <span className="nav-label">{item.label}</span> <span className="fa arrow"></span></Link>
                        <ul className={item.link === this.state.location ? 'nav nav-second-level collapse in' : 'nav nav-second-level collapse'}>
                            {child}
                        </ul>
                    </li>
                )
            } else {
                return (
                    <li key={index}>
                        <Link to={item.link ? item.link : '#'}><i className={item.icon_class}></i> <span className="nav-label">{item.label}</span></Link>
                    </li>
                );
            }
        });
        var user = JSON.parse(localStorage.getItem('currentUser'));
        return (
            <nav className="navbar-default navbar-static-side" role="navigation">
                <div className="sidebar-collapse">
                    <ul className="nav metismenu" id="side-menu">
                        <li className="nav-header">
                            <div className="dropdown profile-element text-center">
                                <a data-toggle="dropdown" className="dropdown-toggle" href="/">
                                    <img alt="avartar" className="rounded-circle" src={avatar} width="50px" />
                                    <span className="block m-t-xs font-bold">{user.name || ''}</span>
                                    <span className="text-muted text-xs block">{user.is_super_admin ? 'Super Admin' : 'Admin'} <b className="caret"></b></span>
                                </a>
                                <ul className="dropdown-menu animated fadeInRight m-t-xs">
                                    <li><Link to='/profile' className="dropdown-item">Profile</Link></li>
                                    <li><Link to='/profile/changepassword' className="dropdown-item">Change password</Link></li>
                                    <li><a href='#' className="dropdown-item" onClick={this.logout}>Logout</a></li>
                                </ul>
                            </div>
                            <div className="logo-element">
                                IN+
                            </div>
                        </li>
                        {menues}
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Navbar;