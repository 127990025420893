import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';

class SetupCarForm extends Component {
    state = {
        data: {
            id: null,
            license_plate: null,
            car_brand: null,
            car_sub_brand: null,
            color: null,
        },
        redirect: false,
        loading: true,
    }

    retrieveData = id => {
        const url = (this.props.url + '/' + id);

        axios.get(url).then(res => {
            let data = res.data;
            console.log(data)
            // data.branch = (data.branch === true ? '1' : '0');
            // data.contact_customers = data.contact_customers.map((item, index) => { return { name: item.name, telephone: item.telephone } });
            // data.service_years = data.service_years.map((item, index) => { return { name: item.name, price: item.price } });

            const loading = false;

            this.setState({ data, loading });
        });
    }

    getValue = target => {
        let data = this.state.data;
        this.setState({
            data: {
                ...data,
                ...target
            }
        });
    }

    onSubmit = e => {
        let params = this.state.data;
        params.branch = (params.branch === '1' ? true : false);
        const url = this.props.url;
        const redirect = true;
        let loading = true;

        this.setState({ loading });

        Services.submitRequest({ params, url }).then(res => { this.setState({ redirect, loading }) }).catch(errors => { this.setState({ errors, loading }) });
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id !== undefined) {
            this.retrieveData(id);
        } else {
            const loading = false;
            this.setState({ loading });
        }
    }

    getRedirect = () => {
        const redirect = this.state.redirect;

        if (redirect) {
            const url = this.props.url;

            return <Redirect to={url} />;
        }

        return;
    }

    getFields = () => {

        const fields = [
            { label: 'ทะเบียน', name: 'license_plate', type: 'textbox', required: true, value: this.state.data.license_plate },
            { label: 'ยี่ห้อ', name: 'car_brand', type: 'textbox', value: this.state.data.car_brand },
            { label: 'รุ่น', name: 'car_sub_brand', type: 'textbox', value: this.state.data.car_sub_brand },
            { label: 'สี', name: 'color', type: 'textbox', value: this.state.data.color },
            { label: 'Active', name: 'active', type: 'switch', value: this.state.data.active },
        ];

        return fields;
    }

    render() {
        const fields = this.getFields();
        const redirect = this.getRedirect();
        const url = this.props.url;

        return (
            <BoxContainer loading={this.state.loading} errors={this.state.errors}>
                {redirect}
                <DataForm
                    fields={fields}
                    onSubmit={this.onSubmit}
                    url={url}
                    getValue={this.getValue.bind()}
                >
                </DataForm>
            </BoxContainer>
        )
    }
}

export default SetupCarForm
