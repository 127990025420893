import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import SetupUserForm from './SetupUserForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class SetupUser extends Component {
    render() {
        const columns = [
            { label: 'อีเมล', align: 'left', name: 'email', search_type: 'string' },
            { label: 'ชื่อ', align: 'left', name: 'name', search_type: 'string' },
            { label: 'สถานะ', align: 'center', name: 'status', sortable: false },
        ];

        const api_url = '/master/user';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ข้อมูลผู้ใช้งาน' program_type='Master'></TitleHeader>
                    
                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} url={api_url} allow_active={true}></DataTable>)} />
                    
                    <Route path={`${this.props.match.path}/create`} component={props => (<SetupUserForm {...props} url={api_url}></SetupUserForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<SetupUserForm {...props} url={api_url}></SetupUserForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default SetupUser
