import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';

class Expense extends Component {

    onPrintClick = item => {
        const url = (axios.defaults.baseURL + '/report/reserve/' + item.key);
        window.open(url, '_target')
    }
    render() {
        const columns = [
            { label: 'เลขที่', align: 'center', name: 'expense_no', search_type: 'string' },
            { label: 'ลูกค้า', align: 'left', name: 'customers.name', search_type: 'string' },
            { label: 'เลขที่ใบแจ้งหนี้', align: 'center', name: 'invoice_no', sortable: false, },
            { label: 'วันที่', align: 'center', name: 'period', sortable: false, },
        ];

        const api_url = '/transaction/expense';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ใบรับเงิน' program_type='Transaction'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable
                            columns={columns}
                            url={api_url}
                            allow_print={true}
                            allow_trash={false}
                            allow_insert={false}
                            allow_edit={false}
                            onPrintClick={this.onPrintClick}
                        ></DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default Expense
