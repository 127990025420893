import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';

class SetupCustomerForm extends Component {
  state = { 
    data:{
      id: null,
      name: null, 
      tax_no: null, 
      tax_percent: null,
      address: null, 
      address2: null, 
      active: true, 
      branch: '1', 
      branch_text: '', 
      contact_customers: [], 
      service_years: [],
    },
    redirect: false,
    loading: true,
  }

  retrieveData = id => {
    const url = (this.props.url + '/' + id);

    axios.get(url).then(res => {
      let data = res.data;
      data.branch = (data.branch === true ? '1' : '0');
      data.contact_customers = data.contact_customers.map( (item, index) => { return { name: item.name, telephone: item.telephone }});
      data.service_years = data.service_years.map( (item, index) => { return { name: item.name, price: item.price }});

      const loading = false;

      this.setState({ data, loading });
    });
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    let params = this.state.data;
    params.branch = (params.branch === '1' ? true : false);
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    Services.submitRequest({ params, url }).then( res => { this.setState({redirect, loading}) }).catch( errors => {this.setState({ errors,loading })});
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    if(id !== undefined){
      this.retrieveData(id);
    }else{
      const loading = false;
      this.setState({loading});
    }
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    const id = this.state.id;
    const branch_datasource = [
      { key: '1', label: 'สำนักงานใหญ่' },
      { key: '0', label: 'สาขาที่', trigger: true, textbox_name: 'branch_text', textbox_value: this.state.data.branch_text },
    ];

    const fields = [
      { label: 'ชื่อ', name: 'name', type: 'textbox', required: true, value: this.state.data.name },
      { label: 'เลขประจำตัวผู้เสียภาษี', name: 'tax_no', type: 'textbox', value: this.state.data.tax_no },
      { label: 'หักภาษี ณ ที่จ่าย', name: 'tax_percent', type: 'percent', value: this.state.data.tax_percent },
      { label: 'ที่อยู่1', name: 'address', type: 'textarea', value: this.state.data.address },
      { label: 'ที่อยู่2', name: 'address2', type: 'textarea', value: this.state.data.address2 },
      { label: 'Active', name: 'active', type: 'switch', value: this.state.data.active },
      { label: 'สาขา', name: 'branch', type: 'radio', value: this.state.data.branch, datasource: branch_datasource },
    ];

    console.log('active', this.state.data);

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    const contact_controls = [
      { label: 'ชื่อผู้ติดต่อ',  name: 'name' },
      { label: 'เบอร์โทร',  name: 'telephone' },
    ];

    const service_year_controls = [
      { label: 'บริการ',  name: 'name' },
      { label: 'ราคา',  name: 'price', type: 'number' },
    ];

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue.bind()}
        >
          <TableForm name='contact_customers' label='ข้อมูลผู้ติดต่อ' controls={contact_controls} value={this.state.data.contact_customers} getValue={this.getValue.bind()}></TableForm>
          <TableForm name='service_years' label='รายระเอียดบริการรายปี' controls={service_year_controls} value={this.state.data.service_years} getValue={this.getValue.bind()}></TableForm>
        </DataForm>
      </BoxContainer>
    )
  }
}

export default SetupCustomerForm
