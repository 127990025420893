// import React, { Component } from 'react'
// import { BrowserRouter, Route, Link } from 'react-router-dom';
// import FormInput from '../../../components/DataForm/FormInput';
// import axios from '../../../axios';
// import { Services } from '../../Service/Services';

// import swal from 'sweetalert';
// import { Redirect } from 'react-router-dom';

import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'


class SetupSetting extends Component {
    state = {
        data:{
            company_name: '',
            company_address: '',
            telephone: '',
            fax: '',
            email: '',
            tax_no: '',
            password_attempt: '',
        },
        loading: true,
        url: '/master/setting',
        errors: [],
    }

    retrieveData = () => {
      const url = this.state.url;

      axios.get(url).then(res => {
        const loading = false;

        const password_attempt = res.data.find(p => p.code === "SECU01").value;
        const company_name = res.data.find(p => p.code === "COMP01").value;
        const company_address = res.data.find(p => p.code === "COMP02").value;
        const telephone = res.data.find(p => p.code === "COMP03").value;
        const fax = res.data.find(p => p.code === "COMP04").value;
        const email = res.data.find(p => p.code === "COMP05").value;
        const tax_no = res.data.find(p => p.code === "COMP06").value;
        
        const data = {
            company_name,
            company_address,
            telephone,
            fax,
            email,
            tax_no,
            password_attempt,
        };

        this.setState({ data, loading });
      });
    }

    componentDidMount() {
        this.retrieveData();
    }

    getValue = target => {
        let data = this.state.data;
        this.setState({
          data: {
            ...data,
            ...target
          }
        });
    }

    onSubmit = e => {
        e.preventDefault();

        const params = this.state.data;
        const url = this.state.url;

        params.id = 0;

        // axios.put(this.state.url + '/0', {
        //     company_name: this.state.company_name,
        //     company_address: this.state.company_address,
        //     telephone: this.state.telephone,
        //     fax: this.state.fax,
        //     email: this.state.email,
        //     tax_no: this.state.tax_no,
        // }).then( res => Services.response(res) );

        let loading = true;

        this.setState({ loading });

        loading = false;

        Services.submitRequest({ params, url }).then( res => { this.setState({ loading }) }).catch( errors => {this.setState({ errors,loading })});
    }

    getFields = () => {
      const fields = [
        { label: 'ชื่อบริษัท', name: 'company_name', value: this.state.data.company_name },
        { label: 'ที่อยู่บริษัท', name: 'company_address', value: this.state.data.company_address },
        { label: 'เบอร์โทรศัพท์', name: 'telephone', value: this.state.data.telephone },
        { label: 'เบอร์โทรสาร', name: 'fax', value: this.state.data.fax },
        { label: 'อีเมล', name: 'email', value: this.state.data.email },
        { label: 'เลขประจำตัวผู้เสียภาษี', name: 'tax_no', value: this.state.data.tax_no },
      ];

      return fields;
    }

    getSecurityFields = () => {
      const fields = [
        { label: 'จำนวนครั้งที่ Login ผิด', name: 'password_attempt', value: this.state.data.password_attempt },
      ];

      return fields;
    }

    render() {
      const fields = this.getFields();
      const security_fields = this.getSecurityFields();

      return (
        <div>
            <TitleHeader program_name='ตั้งค่าทั่วไป' program_type='Master'></TitleHeader>
            <BoxContainer loading={this.state.loading} errors={this.state.errors}>
                <div className="tabs-container">
                    <ul className="nav nav-tabs" role="tablist">
                        <li><a className="nav-link active" data-toggle="tab" href="#tab-1"> ข้อมูลบริษัท</a></li>
                        <li><a className="nav-link" data-toggle="tab" href="#tab-2">นโยบาย</a></li>
                    </ul>
                    <div className="tab-content">
                        <div role="tabpanel" id="tab-1" className="tab-pane active mb-4">
                            <div className="panel-body">
                                <DataForm
                                    fields={fields}
                                    onSubmit={this.onSubmit}
                                    getValue={this.getValue}
                                ></DataForm>
                            </div>
                        </div>
                        <div role="tabpanel" id="tab-2" className="tab-pane">
                            <div className="panel-body">
                                <DataForm
                                    fields={security_fields}
                                    onSubmit={this.onSubmit}
                                    getValue={this.getValue}
                                ></DataForm>
                            </div>
                        </div>
                    </div>
                </div>
            </BoxContainer>
        </div>
      )
    }
}

export default SetupSetting
