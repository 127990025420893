import React, { Component } from 'react'
import DataForm from './../../../components/DataForm/DataForm';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TableForm from '../../../components/TableForm/TableForm';


class SetupEmployeeForm extends Component {
  state = {
    data:{
      name: null,
      telephone: null,
      license_plate: null,
      address: null,
      remark: null,
      is_outsource: true,
      active: true,
      identity_no: null,
      employee_license_plates: [],
    },
    redirect: false,
    loading: true,
  }

  retrieveData = id => {
    const url = (this.props.url + '/' + id);

    axios.get(url).then(res => {
      let data = res.data;

      const loading = false;

      this.setState({ data, loading });
    });
  }

  getValue = target => {
    let data = this.state.data;
    this.setState({
      data: {
        ...data,
        ...target
      }
    });
  }

  onSubmit = e => {
    let params = this.state.data;
    const id = params.id;
    const url = this.props.url;
    const redirect = true;
    let loading = true;

    this.setState({ loading });

    loading = false;

    Services.submitRequest({ params, url }).then( res => { this.setState({redirect, loading}) }).catch( errors => {
      this.retrieveData(id);
      this.setState({ errors })
    });
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    if(id !== undefined){
      this.retrieveData(id);
    }else{
      const loading = false;
      this.setState({loading});
    }
  }

  getRedirect = () => {
    const redirect = this.state.redirect;
    
    if (redirect) {
      const url = this.props.url;

      return <Redirect to={url} />;
    }

    return;
  }

  getFields = () => {
    const fields = [
      { label: 'หมายเลขบัตรประชาชน', name: 'identity_no', type: 'textbox', value: this.state.data.identity_no },
      { label: 'ชื่อ', name: 'name', type: 'textbox', required: true, value: this.state.data.name },
      { label: 'เบอร์โทร', name: 'telephone', type: 'textbox', value: this.state.data.telephone },
      { label: 'ที่อยู่', name: 'address', type: 'textarea', value: this.state.data.address },
      { label: 'หมายเหตุ', name: 'remark', type: 'textarea', value: this.state.data.remark },
      { label: 'รถร่วม', name: 'is_outsource', type: 'switch', value: this.state.data.is_outsource },
      { label: 'Active', name: 'active', type: 'switch', value: this.state.data.active },
    ];

    return fields;
  }

  render() {
    const fields = this.getFields();
    const redirect = this.getRedirect();
    const url = this.props.url;

    return (
      <BoxContainer loading={this.state.loading} errors={this.state.errors}>
        {redirect}
        <DataForm
          fields={fields}
          onSubmit={this.onSubmit}
          url={url}
          getValue={this.getValue.bind()}
        ></DataForm>
      </BoxContainer>
    )
  }
}

export default SetupEmployeeForm
