import React, { Component } from 'react';
import Switcher from './../Switcher/Switcher';
import LabelStatus from './../LabelStatus/LabelStatus';
import DatePicker from '../DatePicker/DatePicker';
import Timepicker from '../Timepicker/Timepicker';
import Dropdown from '../Dropdown/Dropdown';
import RadioInput from '../RadioInput/RadioInput';
class FormInput extends Component {
  state = {
  }
  componentDidMount() {

  }

  handleKeyDown = e => {
    if (this.props.onKeyDown && this.props.onKeyDown instanceof Function) {
      this.props.onKeyDown(e);
    }
  }

  SwitchHandler = (active) => {
    this.props.getValue(active);
  }
  onChange = (event) => {
    this.props.getValue({ [event.target.name]: event.target.value });
  }
  datepickerCallback = (event) => {
    this.props.datepickerCallback({ [event.target.name]: event.target.value });
  }
  timepickerCallback = (event) => {
    this.props.timepickerCallback({ [event.target.name]: event.target.value });
  }
  selectValue = (event) => {
    this.props.selectValue(event.target.value);
  }
  render() {
    const field = this.props.field;

    let input = (<input type="text" className="form-control" id={field.name} name={field.name} required={field.required} placeholder={field.placeholder}
      value={field.value || ''}
      onChange={this.onChange} />);

    if (field.type === 'percent') {
      input = (<div className="input-group">
        <input type="number" className="form-control" id={field.name} name={field.name} required={field.required} placeholder={field.placeholder} value={field.value || ''} onChange={this.onChange} />
        <span className="input-group-addon">
          <i className="fa fa-percent"></i>
        </span>
      </div>)
    }
    else if (field.type === 'email') {
      input = <input type="email" className="form-control" id={field.name} placeholder="name@example.com" name={field.name} required={field.required}
        value={field.value || ''}
        onChange={this.onChange} />;
    } else if (field.type === 'text') {
      input = <LabelStatus status={field.value}></LabelStatus >
    } else if (field.type === 'label') {
      input = <span className="">{field.value}</span>
    } else if (field.type === 'switch') {
      input = <Switcher name={field.name} value={field.value} onChange={this.SwitchHandler}></Switcher>
    } else if (field.type === 'textarea') {
      input = <textarea className="form-control" name={field.name} id={field.name} value={field.value || ''}
        onChange={this.onChange}></textarea>
    } else if (field.type === 'ddl') {
      input = <Dropdown name={field.name} datasource={field.datasource} required={field.required} disabled={field.disabled} value={field.value} onChange={this.props.onChange || field.onChange} allow_new_item={field.allow_new_item}></Dropdown>
    } else if (field.type === 'dropdownlist' && field.datasource !== undefined && field.datasource !== null) {
      const options = field.datasource.map((item, index) => {
        if (item.active) {
          return <option key={item.id} value={item.id}>{item.name}</option>
        }
        return null;
      });
      input = <select className="form-control" id={field.name} name={field.name} defaultValue={field.value}>{options}</select>
    } else if (field.type === 'dropdownlist_select_value' && field.datasource !== undefined && field.datasource !== null) {
      const options = field.datasource.map((item, index) => {
        if (item.active) {
          return <option key={item.id} value={item.id}>{item.name}</option>
        }
        return null;
      });
      input = <select className="form-control" id={field.name} name={field.name} defaultValue={field.value} onChange={this.selectValue} disabled={field.disabled}>{options}</select>
    } else if (field.type === 'datepicker') {
      input = <DatePicker name={field.name} datepickerCallback={this.datepickerCallback} value={field.value || ''} isRange={field.isRange} required={field.required}></DatePicker >
    } else if (field.type === 'province' && field.datasource !== undefined) {
      const options = field.datasource.map((item, index) => <option key={item.id} value={item.id}>{item.name}</option>)
      input = <select className="form-control" id={field.name} name={field.name} defaultValue={field.value}>{options}</select>
    } else if (field.type === 'timepicker') {
      input = <Timepicker name={field.name} timepickerCallback={this.timepickerCallback} value={field.value || ''} required={field.required}></Timepicker >
    }
    else if (field.type === 'radio' && field.datasource !== undefined) {
      const radio = field.datasource.map((item, index) => {
        const checked = (field.value == item.key);
        const id = (field.name + '_' + item.key);
        const textbox_name = item.textbox_name;
        const textbox_value = item.textbox_value;

        const trigger = item.trigger;
        return (
          <RadioInput
            textbox_name={textbox_name}
            textbox_value={textbox_value}
            key={index}
            trigger={item.trigger}
            value={item.key}
            checked={checked}
            name={field.name}
            label={item.label}
            onChange={this.onChange}
          >
          </RadioInput>
        )
      }
      )

      input = (<div>{radio}</div>);
    }

    // else if (field.type === 'textbox') {
    //   input = <input type="text" className="form-control" id={field.name} placeholder="name@example.com" name={field.name}
    //     value={this.props.data ? this.props.data : null} />
    // }
    const label_class_name = ['col-form-label'];
    if (field.required) label_class_name.push('field-required');

    let container_class_name = ['form-group'];

    if(this.props.className){
      container_class_name.push(this.props.className);
    }

    if (field.label === undefined) {
      return input;
    } else {
      return (
        <div className={container_class_name.join(' ')}>
          <label className={label_class_name.join(' ')} htmlFor={field.name}>{field.label}:</label> {input}
        </div>
      );
    }
  }
}

FormInput.defaultProps = {
  field: {}, // type, value, name, required, label, datasource
  onChange: null,
  className: '',
}

export default FormInput
