import { BehaviorSubject } from 'rxjs';
// import config from 'config';
// import { handleResponse } from '../_helpers/index';
import axios from '../../../axios';
import swal from 'sweetalert';
import { history } from '../_helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function login(email, password, remember) {
    return axios.post('/auth/login', { email, password, remember }).then(res => {
        // localStorage.removeItem('currentUser');
        // if (res.data.status) {
            localStorage.setItem('currentUser', JSON.stringify(res.data));
            currentUserSubject.next(res.data);
            // return { 'status': true, 'message': res.data.message };

        // }
        // return { 'status': false, 'message': res.data.message };
    });
}

function logout() {
    swal({
        title: "Logout",
        text: "คุณต้องการออกจากระบบหรือไม่",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then((logout) => {
        if (logout) {
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser');
            currentUserSubject.next(null);
            history.push("/")
        }
    });
}
