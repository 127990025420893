import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import FormInput from '../../../components/DataForm/FormInput';
import JobForm from './JobForm';
import JobReport from './JobReport';
import JobIndex from './JobIndex';
import Dropdown from '../../../components/Dropdown/Dropdown';
import axios from '../../../axios';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'


class Job extends Component {
    state = {
        
    }

    render() {
        const api_url = '/transaction/job';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ข้อมูลใบงาน' program_type='Transaction'></TitleHeader>
                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <JobIndex api_url={api_url}></JobIndex>
                    )} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<JobForm {...props} url={api_url}></JobForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<JobForm {...props} url={api_url}></JobForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default Job
