import React, { Component } from 'react'
import SearchTool from '../../../components/DataTable/SearchTool';
import Pagination from '../../../components/Pagination/Pagination';
import axios from '../../../axios';

import { Redirect, Link } from 'react-router-dom';

class JobWizardSelectEmployee extends Component
{
	constructor(props){
        super(props);

        this.keyword = React.createRef();
    }

	state = {
	    datasource: {
	    	employee_datasource: []
	    },
	    criteria: {
	        keyword: '',
	        page_number: 1,
	        total_records: 0,
	        total_pages: 1,
	        records_per_page: 10,
	    },
	}

	componentWillMount = () => {
	    // this.retrieveData();
	}

	triggerEmployee = item => {
		this.props.onSelect(item, this.props.is_outsource);
	}


	getDatasource = () => {
		const employees = this.state.datasource.employee_datasource.map( (item, index) => {
			const values = item.values;
			const { name, telephone, available, jobs } = values;

			const status = (available === true) ? (<span className='label label-success'>ว่าง</span>) : (<span className='label label-danger'>ไม่ว่าง</span>);
			const job_usage = jobs.map( (job, job_index) => {
				return (
					<li key={job_index}>
						<Link to={'/transaction/job/' + job.id + '/edit'} target='_blank'>{job.document_no}</Link>
					</li>
				)
			});

			const item_index = this.props.value.findIndex(i => i.key === item.key);
			const checked = (item_index !== -1);

			return (
				<tr key={index}>
					<td className='text-center'><input checked={checked} type='checkbox' id={ ('select_' + item.key) } onChange={(e) => this.triggerEmployee(item) } /></td>
					<td><label htmlFor={ ('select_' + item.key) }>{name}</label></td>
					<td><label htmlFor={ ('select_' + item.key) }>{telephone}</label></td>
					<td className='text-center'>
						{status}
						<ul className='list-unstyled'>{job_usage}</ul>
					</td>
				</tr>
			);
		});

		return employees;
	}

	getTableHeaders = () => {
		const table_headers = (
			<React.Fragment>
				<th>ชื่อ - นามสกุล</th>
				<th>เบอร์โทร</th>
				<th>สถานะ</th>
			</React.Fragment>
		);

		return table_headers;
	}

	retrieveData = () => {
		this.props.onLoading(true);
		const { transaction_start_date, transaction_end_date, is_outsource } = this.props;
		const params = {
			transaction_start_date,
			transaction_end_date,
			...this.state.criteria,
			employee_type: (is_outsource ? 'o' : 'e'),
		}

		axios.post('/lov/employees', params).then(res => {
		    const employee_datasource = res.data;
		    const total_records = parseInt(res.headers.total_records);
		    const criteria = this.state.criteria;
		    const records_per_page = criteria.records_per_page;

		    let total_pages = 1;
		    if (records_per_page === 0 || total_records === 0) {
		        total_pages = 1;
		    } else {
		        total_pages = Math.ceil(total_records / records_per_page);
		    }

		    criteria.total_records = total_records;
		    criteria.total_pages = total_pages;
		    
		    this.setState({
		    	criteria,
		        datasource: {
		        	...this.state.datasource,
		            employee_datasource
		        },
		    });

		    this.props.onLoading(false);
		});
	}

	onSearch = () => {
	    this.retrieveData();
	}

	onKeywordChange = e => {
	    const { value } = e.target;
	    const criteria = this.state.criteria;
	    criteria.keyword = value;

	    this.setState({ criteria });
	}

	handleKeyDown = e => {
	    if(e.keyCode === 13){
	        e.preventDefault();
	        this.retrieveData();
	    }
	}

	changePage = page_number => {
	    const criteria = this.state.criteria;
	    criteria.page_number = page_number;

	    this.setState({ criteria });
	    this.retrieveData();
	}

	changeRecordPerPage = records_per_page => {
	    const criteria = this.state.criteria;
	    criteria.records_per_page = records_per_page;
	    criteria.page_number = 1;

	    this.setState({ criteria });
	    this.retrieveData();
	}

	getStartRowRunning = () => {
	    let start_running = ((this.state.criteria.page_number - 1) * this.state.criteria.records_per_page);

	    return start_running + 1;
	}

	getEndRowRunning = () => {
	    let start_row_running = this.getStartRowRunning();
	    let end_running = (parseInt(start_row_running) + parseInt(this.state.criteria.records_per_page)) - 1;

	    if (this.state.criteria.records_per_page === 0 || end_running > this.state.criteria.total_records) return this.state.criteria.total_records;
	    else return end_running;
	}

	componentDidUpdate = (prevProps, prevState) => {
		if(prevProps.reload === false && this.props.reload === true){
			this.retrieveData();
		}
	}

	render = () => {
		const table_headers = this.getTableHeaders();
		const datasource = this.getDatasource();

		return (
			<div>
				<div className="input-group mb-3">
				    <span className="input-group-prepend">
				        <button type="button" className="btn btn-primary" onClick={this.onSearch}>
				            <i className="fa fa-search"></i>
				        </button>
				    </span>
				    <input type="text" className="form-control" placeholder="Search..." value={this.state.criteria.keyword} ref={this.keyword} onChange={this.onKeywordChange} onKeyDown={this.handleKeyDown} />
				</div>
				<SearchTool
				    changeRecordPerPage={this.changeRecordPerPage} allow_insert={false}
				></SearchTool>
				<table className='table table-striped table-bordered table-hover'>
				    <thead>
				        <tr>
				            <th className="text-center">#</th>
				            {table_headers}
				        </tr>
				    </thead>
				    <tbody>
				        {datasource}
				    </tbody>
				</table>
				<div>
				    <div className="pull-right">
				        <Pagination totalPages={this.state.criteria.total_pages} onPageChange={this.changePage} currentPage={this.state.criteria.page_number}></Pagination>
				    </div>
				    <div className="pull-left">Showing {this.getStartRowRunning()} to {this.getEndRowRunning()} of {this.state.criteria.total_records} records</div>
				    <div className="clearfix"></div>
				</div>
			</div>
		);
	}
};

JobWizardSelectEmployee.setDefaultProps = {
	value: [],
	transaction_start_date: null,
	transaction_end_date: null,
	onSelect: [],
	onLoading: [],
	reload: false,
}

export default JobWizardSelectEmployee;