import React, { Component } from 'react'
import { authenticationService } from '../../pages/Authentication/Service/authentication.service';

class Navbar extends Component {
    logout() {
        authenticationService.logout();
    }
    render() {
        return (
            <nav className="navbar navbar-static-top white-bg App-mb" role="navigation">
                <div className="navbar-header">
                    <a className="navbar-minimalize minimalize-styl-2 btn btn-primary " href="/"><i className="fa fa-bars"></i> </a>
                    {/* <form role="search" className="navbar-form-custom" method="post" action="#">
                        <div className="form-group">
                            <input type="text" placeholder="Search for something..." className="form-control" name="top-search"
                                id="top-search"></input>
                        </div>
                    </form> */}
                </div>
                <ul className="nav navbar-top-links navbar-right">
                    <li>
                        <button className='btn btn-link' onClick={this.logout}><i className="fa fa-sign-out"></i> Log out</button>
                    </li>
                </ul>

            </nav>
        )
    }
}

export default Navbar
