import axios from 'axios';

const config = { baseURL: '', headers: { 'Access-Control-Allow-Origin': '*' }};
if(process.env.NODE_ENV === 'production'){
	// production
	// config.baseURL = 'http://45.76.177.95/api/api';
	config.baseURL = 'http://sirichat.incoze.com/api/api';
	// config.baseURL = 'https://ss.sonthichat.co.th/api/api';
}
else{
	// developent
	// config.baseURL = 'http://sirichat.incoze.com/api/api';
	config.baseURL = '/';
}

const instance = axios.create(config);

instance.defaults.headers.common['Authorization'] = 'Auth Token';

export default instance;