import React, { Component } from 'react';
import './InputNumber.css';

const $ = window.$;

class InputNumber extends Component {
    constructor(props) {
        super(props);
        this.element = React.createRef();
    }

    componentDidMount() {
        $(this.element.current).keyup(function (event) {
            // skip for arrow keys
            if (event.which >= 37 && event.which <= 40) return;

            // format number
            // $(this).val(function (index, value) {
            //     return value
            //         .replace(/\D/g, "")
            //         .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // });
        });

    }

    render() {
        const value = (this.props.value ? this.props.value : '');
        let defaultClassName = ['form-control', 'form-control-sm'];
        let propClassName = this.props.className;

        const className = [ ...propClassName, ...defaultClassName ];
        const placeholder = this.props.placeholder;

        return (
            <div className='input-number-container'>
                <input type="text" ref={this.element} placeholder={placeholder} className={className.join(' ')} name={this.props.name} onChange={this.props.onChange} value={value} />
            </div>
        );
        // return (
        //     <div className="input-group">
        //         <input type="text" placeholder={placeholder} className={className.join(' ')} name={this.props.name} onChange={this.props.onChange} value={value} />
        //         <span className="input-group-addon">฿</span>
        //     </div>
        // )
    }
}

InputNumber.defaultProps = {
    value: '',
    className: [],
    placeholder: '',
    onChange: {},
}

export default InputNumber;