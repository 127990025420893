import React, { Component } from 'react'
import Dropdown from './../../../components/Dropdown/Dropdown';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import { Services } from '../../Service/Services';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class ReportTax extends Component {
    state = {
        loading: false,
        errors: [],
        data: {
          month_start: '',
          month_end: '',
          year_start: '',
          year_end: '',
        },
        report_link: axios.defaults.baseURL + '/report/receipt_tax',
    }

    onSubmit = e => {
      e.preventDefault();

      const { month_start, month_end, year_start, year_end } = this.state.data;
      const params = [
        [ 'month_start', month_start ],
        [ 'month_end', month_end ],
        [ 'year_start', year_start ],
        [ 'year_end', year_end ],
      ];

      const url = this.state.report_link + '?' + params.map( i => (i[0] + '=' + i[1])).join('&');
      
      window.open(url, '_blank');
    }

    onChange = e => {
      const {name, value} = e.target;
      let data = this.state.data;
      data[name] = value;
      this.setState({ data });
    }

    getMonthDatasource = () => {
      const months = [
        { key: 1, label: 'มกราคม' },
        { key: 2, label: 'กุมภาพันธ์' },
        { key: 3, label: 'มีนาคม' },
        { key: 4, label: 'เมษายน' },
        { key: 5, label: 'พฤษภาคม' },
        { key: 6, label: 'มิถุนายน' },
        { key: 7, label: 'กรกฎาคม' },
        { key: 8, label: 'สิงหาคม' },
        { key: 9, label: 'กันยายน' },
        { key: 10, label: 'ตุลาคม' },
        { key: 11, label: 'พฤศจิกายน' },
        { key: 12, label: 'ธันวาคม' },
      ];      

      return months;
    }

    getYearDatasource = () => {
      let date = new Date();
      let year_now = date.getFullYear();
      let years = [];

      for(let i = 2019; i <= year_now; i++){
        years.push({
          key: i,
          label: (i + 543),
        })
      }

      return years;
    }

    render() {
        const month_datasource = this.getMonthDatasource();
        const year_datasource = this.getYearDatasource();

        return (
            <div>
              <TitleHeader program_name='รายงานภาษีขาย' program_type='Report'>
              </TitleHeader>
              <BoxContainer loading={this.state.loading} errors={this.state.errors}>
                <form onSubmit={this.onSubmit}>
                  <div className='form-group row'>
                    <div className="col-6">
                      <label className='col-form-label' htmlFor='year_start'>ปีเริ่มต้น:</label>
                      <Dropdown name='year_start' datasource={year_datasource} value={this.state.data.year_start} onChange={this.onChange} placeholder='All'></Dropdown>
                    </div>
                    <div className="col-6">
                      <label className='col-form-label' htmlFor='year_end'>ปีสิ้นสุด:</label>
                      <Dropdown name='year_end' datasource={year_datasource} value={this.state.data.year_end} onChange={this.onChange} placeholder='All'></Dropdown>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className="col-6">
                      <label className='col-form-label' htmlFor='month_start'>เดือนเริ่มต้น:</label>
                      <Dropdown name='month_start' datasource={month_datasource} value={this.state.data.month_start} onChange={this.onChange} placeholder='All'></Dropdown>
                    </div>
                    <div className="col-6">
                      <label className='col-form-label' htmlFor='month_end'>เดือนสิ้นสุด:</label>
                      <Dropdown name='month_end' datasource={month_datasource} value={this.state.data.month_end} onChange={this.onChange} placeholder='All'></Dropdown>
                    </div>
                  </div>
                  <div className='form-group'>
                    <input type='submit' className='btn btn-primary btn-block' value='พิมพ์รายงาน' />
                  </div>
                </form>
              </BoxContainer>
            </div>
        )
    }
}

export default ReportTax
