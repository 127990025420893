import React, { Component } from 'react';
import './Dropdown.css';

const $ = window.$;

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  componentDidMount = () => {
    // $(this.element.cu)
    $(this.element.current).val(this.props.value).trigger('change');
    this.rerender();
  }

  rerender = () => {
    const options = {
      placeholder: this.props.placeholder,
      allowClear: true
    }

    if(this.props.allow_new_item === true){
      options.tags = true;
      options.createTag = function (params) {
        return {
          id: params.term,
          text: params.term,
          newOption: true
        }
      };
      options.templateResult = function (data) {
        var $result = $("<span></span>");

        $result.text(data.text);

        if (data.newOption) {
          $result.append(" <em>(New)</em>");
        }

        return $result;
      }
    }

    $(this.element.current).select2(options).on('change.select2', e => {      
      // var data = e.params.data;
      const { name, value } = e.target;

      const params = {
        target: { name, value }
      }

      if(this.props.value !== value && this.props.onChange) this.props.onChange(params);
    });
  }

  componentDidUpdate = () => {
    $(this.element.current).val(this.props.value).trigger('change');
  }

  render() {
    const options = this.props.datasource.map((item, index) => <option key={index} value={item.key}>{item.label}</option>)

    if(this.props.required === false){
      options.unshift(<option value='' key={-1} checked>{this.props.placeholder}</option>);
    }else{
      options.unshift(<option value='' key={-1} disabled>{this.props.placeholder}</option>);
    }

    return <select style={{width: '100%'}} ref={this.element} required={this.props.required} className="form-control" id={this.props.name} name={this.props.name}>{options}</select>
  }
}

Dropdown.defaultProps = {
  datasource: [],
  name: '',
  disabled: false,
  value: '',
  onChange: [],
  required: false,
  placeholder: 'Please select',
  allow_new_item: false,
}

export default Dropdown
