import swal from 'sweetalert';
import axios from '../../axios';
import * as moment from 'moment';
const $ = window.$;
// const MONTHS = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
// const MONTHS_TH = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
const FULL_MONTHS_TH = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
export const Services = {
    response, error,
    getThaiDateFullFormat,
    convertDate, convertToMoney, convertMoneyToInt, convertToDate, submitRequest, goToTop, convertToDecimal
};

function goToTop(){
    var body = $('html, body');
    body.stop().animate({ scrollTop:0}, 500, 'swing');
}

function submitRequest(params) {
    const default_params = {
        // method: 'post',
        url: '',
        params: [],
    }

    const params_request = {
        ...default_params,
        ...params,
    }

    const promise = new Promise((resolve, reject) => {
        let request;
        const id = params_request.params.id;
        const url = params_request.url;
        if(id !== undefined && id !== null){
            // Update
            request = axios.put((url + '/' + id), params_request.params);
        }else{
            // Insert
            request = axios.post(url, params_request.params);
        }

        request.then( result => {

            swal({
                title: "Success",
                text: 'บันทึกข้อมูลเสร็จสิ้น',
                icon: "success",
                button: "OK",
            }).then( res => { resolve(result); } );
            
        }).catch( res => { 
            var msg = [];
            for (var i in res.response.data.errors) {
                res.response.data.errors[i].map(item => {
                    msg.push(item);
                });
            }

            this.goToTop();
            reject(msg); 
        });
    });

    return promise;
}

function response(axios_promise) {
    var promise = new Promise((resolve, reject) => {
        axios_promise.then( res => {
            swal({
                title: "Success",
                text: 'บันทึกข้อมูลเสร็จสิ้น',
                icon: "success",
                button: "OK",
            }).then( res => { resolve(res); } );
        }).catch( res => {
            var errors = [];
            if (res.status === 500) {
                var error_message = res.response.statusText;

                errors.push(error_message);
            } else {
                var msg = [];
                for (var i in res.response.data.errors) {
                    res.response.data.errors[i].map(item => {
                        msg.push(item);
                    });
                }

                errors = msg;
            }

            reject(errors);
        });
    });

    return promise;
}
function error(error) {
    var promise = new Promise((resolve, reject) => {
        var error_message = '';
        if (error.status === 500) {
            error_message = error.response.statusText;
        } else {
            var msg = [];
            for (var i in error.response.data.errors) {
                error.response.data.errors[i].map(item => {
                    msg.push(item);
                });
            }

            console.log(msg);

            error_message = msg.join('\n');

            // Show error in container will be good
        }
        swal({
            title: error_message,
            icon: "error",
            button: "OK",
        }).then( res => {
            resolve(res);
        });
    });

    return promise;
}

function getThaiDateFullFormat(_date) {
    if (_date === null) return '';
    var date = new Date(_date);

    var day = date.getDate();
    var month = FULL_MONTHS_TH[date.getMonth()];
    var year = date.getFullYear() + 543;
    return day + " " + month + " " + year;
}

function convertDate(_date) {
    return moment(_date).format("DD/MM/YYYY");
}

function convertToDate(_date, format = 'DD/MM/YYYY'){
    return moment(_date, format);
}

function convertToMoney(money) {
    if(money!== undefined && money !== null && money !== ''){
        money = parseFloat(money);
        return money.toLocaleString(undefined, { maximumFractionDigits: 2 });
    }

    return '';
}
function convertMoneyToInt(money) {
    return parseInt(money.replace(/[^0-9]/g, ''));
}

function convertToDecimal(numeric){
    const number = parseFloat(numeric);

    if(isNaN(number)){
        return 0;
    }else{
        return number;
    }
}