import React, { Component } from 'react'
import { Link } from "react-router-dom";
import axios from '../../axios';
const Chart = require('chart.js');
const $ = window.$;
var HtmlToReactParser = require('html-to-react').Parser;


class Dashboard extends Component {
    state = {
        jobs: [],
        job_count: 0,
        invoice_count: 0,
        invoices: [],
        invoice_detail: []
    }
    componentDidMount() {
        axios.get('/dashboard').then(res => {
            var lineData = {
                labels: res.data.label,
                datasets: [
                    {
                        label: "รถร่วม",
                        backgroundColor: "rgba(26,179,148,0.5)",
                        borderColor: "rgba(26,179,148,0.7)",
                        pointBackgroundColor: "rgba(26,179,148,1)",
                        pointBorderColor: "#fff",
                        // data: [100, 200, 300, 400, 3000, 1000]
                        data: res.data.count_outsource
                    },
                    {
                        label: "รถภายใน",
                        backgroundColor: "rgba(220,220,220,0.5)",
                        borderColor: "rgba(220,220,220,1)",
                        pointBackgroundColor: "rgba(220,220,220,1)",
                        pointBorderColor: "#fff",
                        // data: [180, 400, 1500, 3000, 1500]
                        data: res.data.count_not_outsource
                    }
                ]
            };
            var lineOptions = {
                responsive: true
            };
            var ctx = document.getElementById("lineChart").getContext("2d");
            new Chart(ctx, { type: 'line', data: lineData, options: lineOptions });

            var htmlToReactParser = new HtmlToReactParser();


            const jobs = res.data.jobs.map((item, key) => {
                return (
                    <tr key={key}>
                        <td className='text-center'><Link to={'/transaction/job/' + item.id + '/edit'} target='_blank'>{item.document_no}</Link></td>
                        <td>{item.customer_name}</td>
                        <td>{item.transaction_date}</td>
                        <td>{htmlToReactParser.parse(item.employee)}</td>
                    </tr>
                )
            });

            const invoice_detail = res.data.invoice_detail.map((item, key) => {
                const id = item.map((item, key) => {

                    if(item.invoice_type === 'N'){
                        return (
                            <tr key={key}>
                                <td className='text-center'><Link to={'/transaction/job/' + item.id + '/edit'} target='_blank'>{item.document_no}</Link></td>
                                <td className='text-center'>{item.transaction_date}</td>
                                <td>{htmlToReactParser.parse(item.employee)}</td>
                            </tr>
                        );
                    }else{
                        return (
                            <tr key={key}>
                                <td colSpan='3' className='text-left'>{item.service}</td>
                            </tr>
                        )
                    }
                });
                return id;
            });

            const invoices = res.data.invoices.map((item, key) => {

                let column_headers;

                if(item.invoice_type === 'N'){
                    column_headers = (
                        <tr>
                            <th className='text-center'><strong>เลขที่ใบงาน</strong></th>
                            <th className='text-center'><strong>วันที่</strong></th>
                            <th className='text-center'><strong>พนักงาน</strong></th>
                        </tr>
                    );
                }else{
                    column_headers = (
                        <tr>
                            <th colSpan='3' className='text-center'><strong>บริการ</strong></th>
                        </tr>
                    );
                }

                return (
                    <React.Fragment key={key}>
                        <tr>
                            <td className='text-center'>
                                <button className='btn btn-link pt-0' onClick={this.expand}>
                                    <i className="fa fa-chevron-right"></i>
                                </button>
                            </td>
                            <td className='text-center'><Link to={'/transaction/invoice/' + item.id + '/edit'} target='_blank'>{item.invoice_no}</Link></td>
                            <td>{item.customer_name}</td>
                            <td>{item.invoice_type_text}</td>
                            <td className='text-center'>{item.transaction_date}</td>
                        </tr>
                        <tr className='d-none'>
                            <td></td>
                            <td colSpan='4'>
                                <table className='table'>
                                    <thead>
                                        {column_headers}
                                    </thead>
                                    <tbody>
                                        {invoice_detail[key]}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </React.Fragment>
                )
            });

            this.setState({
                job_count: res.data.job_count,
                jobs: jobs,
                invoice_count: res.data.invoice_count,
                invoices: invoices,
                invoice_detail: invoice_detail
            });
        });
    }

    expand = e => {
        const $expander = $(e.target).closest('tr').next('tr');
        let $icon = $(e.target);
        if($icon.is(':button')){
            $icon = $icon.find('i:first');
        }

        if ($expander.hasClass('d-none')) {
            $expander.hide().removeClass('d-none').fadeIn();
            $icon.removeClass('fa-chevron-right').addClass('fa-chevron-down');
        } else {
            $icon.removeClass('fa-chevron-down').addClass('fa-chevron-right');
            $expander.fadeOut({
                done: function () {
                    $expander.hide().addClass('d-none')
                }
            });
        }
    }

    render() {
        return (
            <div>
            <div className="wrapper wrapper-content pb-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox ">
                                <div className="ibox-content text-center">
                                    <Link className='btn btn-outline-secondary btn-lg w-50' to="/transaction/job_wizard">เปิดใบงานใหม่</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper wrapper-content pb-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox ">
                                <div className="ibox-content">
                                    <div>
                                        <canvas id="lineChart" height="70"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper wrapper-content pb-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox ">
                                <div className="ibox-title">
                                    <span className="label label-primary float-right">{this.state.job_count} ใบงาน</span>
                                    <h5>ใบงานที่ยังไม่ถูกวางบิล</h5>
                                </div>
                                <div className="ibox-content">
                                    <div>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>เลขที่ใบแจ้งหนี้</th>
                                                    <th>ลูกค้า</th>
                                                    <th>วันที่ใบแจ้งหนี้</th>
                                                    <th>พนักงาน</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.jobs}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper wrapper-content">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox ">
                                <div className="ibox-title">
                                    <span className="label label-primary float-right">{this.state.invoice_count} ใบงาน</span>
                                    <h5>ใบวางบิลที่ยังไม่ได้ออกใบเสร็จ</h5>
                                </div>
                                <div className="ibox-content">
                                    <div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>เลขที่</th>
                                                    <th>ลูกค้า</th>
                                                    <th>ประเภทใบแจ้งหนี้</th>
                                                    <th>วันที่</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.invoices}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard