import React, { Component } from 'react'
import swal from 'sweetalert';
import { Services } from '../../Service/Services';
import FormInput from './../../../components/DataForm/FormInput';
import JobDetailRow from './JobDetailRow';
import axios from '../../../axios';
const $ = window.$;

class JobDetailForm extends Component {
	constructor(props) {
		super(props);

		// this.modal_job_detail = React.createRef();
		// this.source_place = React.createRef();
		//       this.dest_place = React.createRef();
	}

	state = {
		data: [],
		// errors: [],
	}

	handleChange = (json_value, key) => {
		let data = this.state.data;
		let value_key = Object.keys(json_value)[0];
		let value_value = Object.values(json_value)[0];

		data[key][value_key] = value_value;

		this.setState({ data });

		this.props.getValue(data);
	}

	getNewEmployee = employee => {
		const expenses = this.props.expense_datasource.map(expense => {
			return {
				id: null,
				expense_id: expense.key,
				price: ''
			}
		});

		const new_employee = {
			employee_id: employee.id,
			name: employee.value.name,
			license_plate: employee.value.license_plate,
			start_time: '',
			end_time: '',
			start_kilo: '',
			end_kilo: '',
			expenses,
		};

		return new_employee;
	}

	getDefaultData = () => {
		const defaultData = {
			id: null, transaction_date: '', source_place: '', dest_place: '',
			employees: [] // employee_id: '', name: '', license_platestart_time: '', end_time: '', start_kilo: '', end_kilo: '', expenses: [ { id: null, expense_id: 1, price: '' } ]
		};

		if (this.props.employees.length > 0) {
			defaultData.employees = this.props.employees.map(item => {
				const employee = this.getNewEmployee(item);

				return employee;
			});
		}

		return defaultData;
	}

	addHandle = e => {
		e.preventDefault();

		let data = this.state.data;
		const new_data = this.getDefaultData();

		data.push(new_data);

		this.props.getValue(data);
	}

	// clearForm = () => {
	// 	const form = {
	// 		id: null, 
	// 		transaction_date: '', 
	// 		start_time: '', 
	// 		end_time: '', 
	// 		start_kilo: '', 
	// 		end_kilo: '', 
	// 		source_place: '', 
	// 		dest_place: '', 
	// 		bill_price: '', 
	// 		cost_price: '', 
	// 		expenses: [],
	// 		index: null,
	// 	};

	// 	this.setState({ form, errors: [] });
	// }

	// editHandler = (item, index) => {

	// 	const form = this.state.data[index];
	// 	form.index = index;

	// 	this.setState({ form });

	// 	this.showModal();
	// }

	// showModal = () => {
	// 	$(this.modal_job_detail.current).modal('show').on('hidden.bs.modal', () => this.clearForm());
	// }

	// closeModal = () => {
	// 	$(this.modal_job_detail.current).modal('hide')
	// }

	deleteHandler = (index) => {
		swal({
			title: "Delete",
			text: "ต้องการลบข้อมูลนี้หรือไม่",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				let data = this.state.data;
				data.splice(index, 1);

				this.props.getValue(data);
			}
		});
	}

	componentDidMount = () => {
		// $(this.source_place.current).add(this.dest_place.current).autocomplete({
		//     source: (request, response) => {
		//     	const params = {
		//     		keyword: request.term,
		//     	};

		//         axios.get('/autocomplete/place', { params }).then( res => {
		//             const results = res.data;
		//             response(results);
		//         });
		//     },
		//     change: (e, ui) => {
		//         const value = ui.item ? ui.item.value : e.target.value;
		//         const name = e.target.name;
		//         console.log('change', name, value, ui);

		//         this.setState({ [name]: value });
		//     },
		//     select: (e, ui) => {
		//         const value = ui.item ? ui.item.value : e.target.value;
		//         const name = e.target.name;
		//         console.log('select', name, value, ui);

		//         this.setState({ [name]: value });
		//     }
		// });
	}

	componentWillReceiveProps = nextProps => {
		const value = nextProps.value;
		if (value.length > 0) {
			const job_detail = this.updateEmployeeEachJobDetail(nextProps.value, nextProps.employees);
			this.setState({ data: job_detail });
		}
	}

	updateEmployeeEachJobDetail(job_details, employees) {
		job_details = job_details.map(item => {
			// console.log(item.employees);
			const employee_id_list = item.employees.map(job_employee => job_employee.employee_id);
			let new_job_employees = [];

			for (var i = 0; i < employees.length; i++) {
				const employee = employees[i];
				const index = employee_id_list.indexOf(employee.id);

				if (index === -1) {
					// Add to job_employee
					const new_employee = this.getNewEmployee(employee);

					new_job_employees.push(new_employee);
				} else {
					new_job_employees.push({ ...item.employees[index] });
				}
			};

			item.employees = new_job_employees;

			return item;
		});

		return job_details;
	}

	render = () => {
		var error_container = '';
		const province_datasource = this.props.province_datasource;
		const expense_datasource = this.props.expense_datasource;

		// if(this.state.errors.length > 0){
		// 	const li = this.state.errors.map((item, index) => <li key={index}><i className='fa fa-times'></i> {item}</li>);
		// 	error_container = <ul className='alert alert-danger list-unstyled'>{li}</ul>
		// 	// console.log(this.state.errors, 'this.state.errors');

		// 	$(this.modal_job_detail.current).animate({ scrollTop: 0 }, 1000);
		// }

		// const job_detail = this.state.data.map( (item, key) => {
		// 	const expenses = item.expenses.map( (expense, index) => {
		// 		const expense_label = this.getExpenseLabel(expense.expense_id);
		// 		const expense_price = Services.convertToMoney(expense.price);

		// 		return <li key={index}><strong>{expense_label}:</strong> {expense_price} ฿</li>
		// 	});

		// 	const transaction_date = (item.transaction_date ? Services.getThaiDateFullFormat(Services.convertToDate(item.transaction_date)) : '');

		// 	return <tr key={key}>
		// 				<td className='text-center'>{transaction_date} เวลา {item.start_time} - {item.end_time}</td>
		// 				<td className='text-left'>{item.source_place} - {item.dest_place}</td>
		// 				<td className='text-right'>{item.start_kilo} - {item.end_kilo}</td>
		// 				<td className='text-right'>{Services.convertToMoney(item.bill_price)} ฿</td>
		// 				<td className='text-right'>{Services.convertToMoney(item.cost_price)} ฿</td>
		// 				<td className='text-left'><ul className='list-unstyled'>{expenses}</ul></td>
		// 				<td className='text-center'>
		// 					<button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" className="btn btn-secondary btn-sm" onClick={() => this.editHandler(item, key)}>
		// 					    <i className="fa fa-pencil"></i> แก้ไข
		// 					</button>
		// 					<button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-sm ml-1" onClick={() => this.deleteHandler(item, key)}>
		// 					    <i className="fa fa-trash"></i> ลบ
		// 					</button>
		// 				</td>
		// 			</tr>
		// });

		const job_detail = this.props.value.map((item, key) => {
			return <JobDetailRow value={item} key={key} onChange={value => this.handleChange(value, key)} onDelete={e => this.deleteHandler(key)} expense_datasource={expense_datasource} province_datasource={province_datasource}></JobDetailRow>
		});

		return (
			<div className='form-group'>
				<label className='col-form-label'>รายละเอียดการใช้งาน:</label>
				<div className='text-right mb-2'>
					<button className='btn btn-info' onClick={this.addHandle}><i className='fa fa-plus'></i> เพิ่มรายละเอียด</button>
				</div>
				<table className='table table-borderless'>
					<thead>
						<tr className='border-top border-bottom'>
							<th>วันที่</th>
							<th>จังหวัด</th>
							<th>สถานที่</th>
							<th>ค่ารถ</th>
							<th>ค่าใช้จ่ายอื่นๆ</th>
							<th>#</th>
						</tr>
					</thead>
					<tbody>
						{job_detail}
					</tbody>
				</table>
			</div>
		);
	}

}

JobDetailForm.defaultProps = {
	expense_datasource: [],
	province_datasource: [],
	getValue: {}, // fn
	value: {},
	// {
	// 	id: null, 
	// 	transaction_date: '', 
	// 	source_place: '', 
	// 	dest_place: '' ,
	// 	employees: [ ] // bill_price: null, cost_price: null, employee_id: '', name: '', license_plate: '', start_time: '', end_time: '', start_kilo: '', end_kilo: '', expenses: [ { id: null, expense_id: 1, price: '' } ]}
	// }
	employees: [],
}

export default JobDetailForm