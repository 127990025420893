import React, { Component } from 'react';
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import { authenticationService } from '../Service/authentication.service';
import { history } from '../../../pages/Authentication/_helpers';
const $ = window.$;

class login extends Component {
    state = {
        
    }
    // constructor(props) {
    //     super(props);
    //     // redirect to home if already logged in
    //     // if (!authenticationService.currentUserValue) {
    //     //     this.props.history.push('/login');
    //     // }
    // }
    componentDidMount() {
        
    }
    onSubmit = e => {
        e.preventDefault();
        var params = {
            email: e.target.email.value,
            password: e.target.password.value,
            remember: e.target.remember.checked,
        }
        authenticationService.login(params.email, params.password, params.remember)
            .then(
                res => {
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                    history.go(0)

                }
            ).catch( error => {
                if(error.response && error.response.data && error.response.data.errors){
                    var messages = [];
                    $.each(error.response.data.errors, (key, value) => { 
                        if(Array.isArray(value)) {
                            messages = messages.concat(value);
                        }else{
                            messages.push(value);
                        }
                    } );

                    swal({
                        title: "Error",
                        text: messages.join('\n'),
                        icon: "error",
                        button: 'OK',
                    })
                }
            } );
    }
    render() {

        return (
            <div className="center-login gray-bg">
                <div className="login animated fadeInDown ">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center logo-name">Sirichat</h1>
                        </div>
                        <div className="col-12">
                            <h3 className="text-center">Welcome to Sirichat</h3>
                        </div>
                        <div className="col-12">

                            <form className="m-t" id="form" onSubmit={this.onSubmit} >
                                <div className="form-group row">
                                    <div className="col-6 mx-auto">
                                        <input type="text" className="form-control" placeholder="Username / Email" name="email" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-6 mx-auto">
                                        <input type="password" className="form-control" placeholder="Password" name="password" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-6 mx-auto">
                                        <button type="submit" className="btn btn-primary block full-width">Login</button>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-6 mx-auto">
                                        <div className="form-group row">
                                            <div className="text-left col">
                                                <label><input type="checkbox" name="remember" /> Remember me</label>
                                            </div>
                                            <div className="text-right col">
                                                <Link to="/forgotpassword"><small>Forgot password?</small></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div >
        )
    }
}
export default login;