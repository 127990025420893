import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import FormInput from '../../../components/DataForm/FormInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import axios from '../../../axios';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
const $ = window.$;


class ReportJob extends Component {
    state = {
        columns: [
            { label: 'เลขที่', align: 'center', name: 'job_document_no', sortable: true, search_type: 'string' },
            { label: 'ลูกค้า', align: 'left', name: 'customer_name', sortable: true, search_type: 'string' },
            { label: 'ประเภทใบแจ้งหนี้', align: 'left', name: 'invoice_type', sortable: true },
            { label: 'วันที่', align: 'center', name: 'place', sortable: false, },
        ],
        api_url: '/report/invoice_receipt',
    }

    componentDidMount() {

    }

    onPrintClick = criteria => {
        criteria.report = 1;

        const criteria_string = $.param(criteria);

        const report_url = (axios.defaults.baseURL + this.state.api_url + '?' + criteria_string);

        // window.location.href = report_url;
        window.open(
            report_url,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    render() {
        const api_url = this.state.api_url;

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='รายงานใบแจ้งหนี้ที่ยังไม่ได้ออกใบเสร็จ' program_type='Report'>

                    </TitleHeader>
                    <Route
                        path={`${this.props.match.path}/`}
                        component={() => (
                            <DataTable
                                columns={this.state.columns}
                                url={api_url}
                                allow_insert={false}
                                allow_print={true}
                                allow_trash={false}
                                allow_edit={false}
                                print_multiple={true}
                                allow_action={false}
                                onPrintClick={this.onPrintClick}>
                            </DataTable>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default ReportJob