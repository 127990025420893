import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import SetupCarForm from './SetupCarForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class SetupCustomer extends Component {
    render() {
        const columns = [
            { label: 'ทะเบียน', align: 'left', name: 'license_plate', search_type: 'string' },
            { label: 'ยี่ห้อ', align: 'center', name: 'car_brand', search_type: 'string' },
        ];

        const api_url = '/master/car';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ข้อมูลรถ' program_type='Master'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} url={api_url} allow_active={true}></DataTable>)} />
                    <Route path={`${this.props.match.path}/create`} component={props => (<SetupCarForm {...props} url={api_url}></SetupCarForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<SetupCarForm {...props} url={api_url}></SetupCarForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default SetupCustomer
