import React, { Component } from 'react'
// import Switch from 'react-switchery';
var Switchery = require('switchery');
const $ = window.$;
require('./Switcher.css');

class Switcher extends Component {
  constructor(props){
    super(props);

    this.element = React.createRef();
    this.prevent = false;
  }

  onChange = (e) => {
    this.props.onChange({ [this.props.name]: e.checked })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.value !== prevProps.value && this.prevent === false){
      $(this.element.current).next('.switchery').trigger('click.withoutchange');
    }
  }

  createSwitchery(){
    const elem = this.element.current;
    const switchery = new Switchery(elem, { size: 'small' }); 

    $(elem).next('.switchery').on('click', e => {
      console.log('click', elem.checked);
      this.prevent = true;
      this.onChange(elem);
    });
  }

  componentDidMount = () => {
    this.createSwitchery();
  }

  render() {
    const checked = this.props.value;
    return (<input type="checkbox" ref={this.element} name={this.props.name} id={this.props.name} defaultChecked={checked} />);
  }
}

export default Switcher
