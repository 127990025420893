import React, { Component } from 'react'
import SearchTool from '../DataTable/SearchTool';
import Pagination from '../Pagination/Pagination';

import axios from '../../axios';

const $ = window.$;

class LOV extends Component {
    constructor(props){
        super(props);

        this.modal = React.createRef();
        this.keyword = React.createRef();
    }

    state = {
        datasource: [], // should return to be { key: xx, values: ['Value 1', 'Value 2', 'Value 3'] }
        criteria: {
            keyword: '',
            page_number: 1,
            total_records: 0,
            total_pages: 1,
            records_per_page: 10,
            // loading: true,
        }
    }

    retrieveData = () => {
        const api = this.props.api;
        const params = {
            ...api.params,
            ...this.state.criteria
        }
        
        axios.post(api.url, params).then( res => {
            const datasource = res.data;
            const total_records = parseInt(res.headers.total_records);
            const criteria = this.state.criteria;
            const records_per_page = criteria.records_per_page;

            let total_pages = 1;
            if (records_per_page === 0 || total_records === 0) {
                total_pages = 1;
            } else {
                total_pages = Math.ceil(total_records / records_per_page);
            }

            criteria.total_records = total_records;
            criteria.total_pages = total_pages;

            this.setState({
                datasource, criteria
            });
        });
    }

    changePage = page_number => {
        console.log('page_number', page_number);
        const criteria = this.state.criteria;
        criteria.page_number = page_number;

        this.setState({ criteria });
        this.retrieveData();
    }

    changeRecordPerPage = records_per_page => {
        console.log('records_per_page', records_per_page);
        const criteria = this.state.criteria;
        criteria.records_per_page = records_per_page;
        criteria.page_number = 1;

        this.setState({ criteria });
        this.retrieveData();
    }

    getStartRowRunning = () => {
        let start_running = ((this.state.criteria.page_number - 1) * this.state.criteria.records_per_page);

        return start_running + 1;
    }

    getEndRowRunning = () => {
        let start_row_running = this.getStartRowRunning();
        let end_running = (parseInt(start_row_running) + parseInt(this.state.criteria.records_per_page)) - 1;

        if (this.state.criteria.records_per_page === 0 || end_running > this.state.criteria.total_records) return this.state.criteria.total_records;
        else return end_running;
    }

    showModal = e => {
        $(this.modal.current).modal('show');
    }

    closeModal = e => {
        $(this.modal.current).modal('hide');
    }

    onHiddenModal = e => {
        console.log('onHiddenModal');
    }

    componentDidMount = () => {
        $(this.modal.current).on('hidden.bs.modal', this.onHiddenModal);
    }

    componentWillMount = () => {
        this.retrieveData();
    }

    onSearch = () => {
        this.retrieveData();
    }

    onKeywordChange = e => {
        const { value } = e.target;
        const criteria = this.state.criteria;
        criteria.keyword = value;

        this.setState({ criteria });
    }

    onSelect = item => {
        const name = this.props.name
        var value = {
            [name]: item
        };

        this.props.onChange(value);
        this.closeModal();
    }

    handleKeyDown = e => {
        if(e.keyCode === 13){
            e.preventDefault();
            this.retrieveData();
        }
    }

    render() {
        const table_headers = this.props.columns.map( (item, index) => <th key={index}>{item.label}</th> );
        const datasource = this.state.datasource.map( (item_row, index_row) => {

            const children = this.props.columns.map( (column, index_col) => {
                const className = (column.className ? column.className : []);
                const value = item_row.values[column.name];

                return <td className={className.join(' ')} key={index_col}>{value}</td>
            });

            children.unshift(<td className='text-center' key={-1}><button className='btn btn-link' onClick={e => { e.preventDefault(); this.onSelect(item_row)}}>เลือก</button></td>)

            return <tr key={index_row}>{children}</tr>
        })

        const { is_button, button_text } = this.props;
        let lov_input;

        if(is_button === true){
            lov_input = <button type="button" className="btn btn-primary" onClick={this.showModal}><i className="fa fa-plus"></i> {button_text}</button>
        }else{
            lov_input = (
                <div className="input-group">
                    <input type="text" className="form-control" disabled value={this.props.value} />
                    <span className="input-group-append">
                        <button type="button" className="btn btn-primary" onClick={this.showModal}><i className="fa fa-search"></i></button>
                    </span>
                </div>
            )
        }

        return (
                <div>
                    {lov_input}
                    <div className="modal fade" role="dialog" ref={this.modal}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header bg-primary">
                                    <h5 className="modal-title">{this.props.title}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="input-group mb-3">
                                        <span className="input-group-prepend">
                                            <button type="button" className="btn btn-primary" onClick={this.onSearch}>
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </span>
                                        <input type="text" className="form-control" placeholder="Search..." value={this.state.criteria.keyword} ref={this.keyword} onChange={this.onKeywordChange} onKeyDown={this.handleKeyDown} />
                                    </div>
                                    <SearchTool
                                        changeRecordPerPage={this.changeRecordPerPage} allow_insert={false}
                                    ></SearchTool>
                                    <table className='table table-striped table-bordered table-hover'>
                                        <thead>
                                            <tr>
                                                <th className="text-center">#</th>
                                                {table_headers}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {datasource}
                                        </tbody>
                                    </table>
                                    <div>
                                        <div className="pull-right">
                                            <Pagination totalPages={this.state.criteria.total_pages} onPageChange={this.changePage} currentPage={this.state.criteria.page_number}></Pagination>
                                        </div>
                                        <div className="pull-left">Showing {this.getStartRowRunning()} to {this.getEndRowRunning()} of {this.state.criteria.total_records} records</div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

LOV.defaultProps = {
    value: '',
    columns: [], // { label: '' }
    name: '',
    title: '',
    api: {
        url: '',
        params: {},
    },
    is_button: false,
    button_text: '',
    onChange: [] // fn
}

export default LOV
