import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class SearchTool extends Component {
    changeRecordPerPage = e => {
        this.props.changeRecordPerPage(parseInt(e.target.value));
    }

    onPrint = () => {
        this.props.onPrintClick();
    }

    render() {
        const path = window.location.pathname;
        const buttons = [];
        const custom_buttons = this.props.buttons;

        if(this.props.allow_print === true && this.props.print_multiple === true){
            buttons.push(<button key={1} type="button" className="btn btn-light" data-toggle='tooltip' data-placemeent='top' data-original-title="Export" title="Export" onClick={this.onPrint}>
                <i className="fa fa-print"></i> พิมพ์ข้อมูล
            </button>);
        }

        if(this.props.allow_trash === true && this.props.trash_multiple === true){
            buttons.push(<button key={2} type="button" className="btn btn-light" data-toggle='tooltip' data-placemeent='top' data-original-title="Trash" title="Trash">
                <i className="fa fa-trash"></i>
            </button>)
        }

        if(this.props.allow_insert === true){
            buttons.push(<Link key={3} to={`${path}/create`} className="btn btn-success" data-toggle='tooltip' data-placemeent='top' data-original-title="Create" title="Create"><i className="fa fa-plus"></i> เพิ่มข้อมูล</Link>)
        }

        return (
            <div className="mb-2 row">
                <div className="col-12 col-md-6">
                    <div className="form-inline my-2">
                        <div className='form-group'>
                            <label htmlFor='page-amount-input' className='mr-2'>Show </label>
                            <select className="form-control form-control-sm" id="page-amount-input" onChange={this.changeRecordPerPage}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={0}>ALL</option>
                            </select>
                            <span className="ml-2">records</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 text-right">
                    <div id="toolbar" className="btn-group">
                        {buttons}
                        {custom_buttons}
                    </div>
                </div>
            </div>
        )
    }
}

SearchTool.defaultProps = {
    changeRecordPerPage: [],
    allow_print: false,
    print_multiple: false,
    trash_multiple: false,
    allow_trash: false,
    allow_insert: true,
    buttons: [],
    onPrintClick: [],
}

export default SearchTool
