import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import InvoiceForm from './InvoiceForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'
import axios from '../../../axios';
class Invoice extends Component {

    onPrintClick = item => {
        const url = (axios.defaults.baseURL + '/report/invoice/' + item.key);
        window.open(url, '_target')
    }
    render() {
        const columns = [
            { label: 'เลขที่', align: 'center', name: 'invoice_no', search_type: 'string' },
            { label: 'ลูกค้า', align: 'left', name: 'customers.name', search_type: 'string' },
            { label: 'ประเภทใบแจ้งหนี้', align: 'left', name: 'invoice_type', sortable: false, },
            { label: 'วันที่', align: 'center', name: 'period', sortable: false, },
        ];

        const api_url = '/transaction/invoice';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ข้อมูลใบแจ้งหนี้' program_type='Transaction'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (
                        <DataTable
                            columns={columns}
                            url={api_url}
                            allow_print={true}
                            onPrintClick={this.onPrintClick}
                        ></DataTable>)} />
                    <Route path={`${this.props.match.path}/create`} component={props => (<InvoiceForm {...props} url={api_url}></InvoiceForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<InvoiceForm {...props} url={api_url}></InvoiceForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default Invoice
