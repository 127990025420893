import React, { Component, Fragment } from 'react'

class LableStatus extends Component {
    render() {
        let status = this.props.status;
        let label = <span className="label label-danger">Locked</span>;
        if (this.props.status) {
            if (status === "A") {
                label = <span className="label label-success">Active</span>;

            } else if (status === "P") {
                label = <span className="label label-info">Pending</span>;
            }
        }
        return (
            <Fragment>
                {label ? label : null}
            </Fragment>
        )
    }
}

export default LableStatus
