import React, { Component } from 'react';
import './DatePicker.css';
const $ = window.$;

class DatePicker extends Component {
    constructor(props) {
        super(props);

        this.container = React.createRef();
        this.start_date = React.createRef();
        this.end_date = React.createRef();
    }

    componentDidMount() {
        // const today_date = new Date();
        // const today_day = today_date.getDate();
        // const today_month = today_date.getMonth() + 1;
        // const today_year = today_date.getFullYear();
        // const today = (today_day + "/" + today_month + "/" + today_year);

        $.fn.datepicker.dates['th'] = {
            days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์", "อาทิตย์"],
            daysShort: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส", "อา"],
            daysMin: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส", "อา"],
            months: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],
            monthsShort: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
            today: "วันนี้"
        };

        $.fn.datepicker.defaults.language = 'th';

        let element = this.start_date.current;

        if (this.props.isRange === true) {
            element = this.container.current;
        }

        $(element).datepicker({
            todayBtn: "linked",
            keyboardNavigation: false,
            forceParse: false,
            calendarWeeks: true,
            autoclose: true,
            format: 'dd/mm/yyyy'
        });

        var props = this.props;

        $(element).change(e => {
            const { value, name } = e.target;
            const prop_value = this.getPropValue(name);

            if (value !== prop_value) props.datepickerCallback(e);
        });
    }
    onChange = e => { }

    getPropValue = name => {
        const name_array = name.split('_');
        let prop_value_current = this.props.value;

        if (!(prop_value_current !== null && Array.isArray(prop_value_current) === true && prop_value_current.length >= 2)) {
            prop_value_current = ['', ''];
        }


        let prop_value;

        if (name_array[name_array.length] === 'start') {
            prop_value = prop_value_current[0];
        } else {
            prop_value = prop_value_current[1];
        }

        return prop_value;
    }

    componentDidUpdate = e => {
        let value = this.props.value;
        const is_range = this.props.isRange;
        const start_date = $(this.start_date.current).val();

        if (is_range === true) {
            if (!(value !== null && Array.isArray(value) === true && value.length >= 2)) {
                value = ['', ''];
            }

            const end_date = $(this.end_date.current).val();

            if (value[0] !== start_date) {
                // set new value
                $(this.start_date.current).val(value[0]).trigger('change');
            }

            if (value[1] !== end_date) {
                // set new value
                $(this.end_date.current).val(value[1]).trigger('change');
            }
        } else {
            if (start_date !== value) {
                $(this.start_date.current).val(value).trigger('change');
            }
        }
    }

    focusInput = e => {
        $(this.start_date.current).datepicker('show');
    }

    getSingleDate = () => {
        const value = (this.props.value || '');

        return (
            <div className="input-group" ref={this.container}>
                <span className="input-group-addon" onClick={() => this.focusInput(this.start_date.current)}>
                    <i className="fa fa-calendar"></i>
                </span>
                <input
                    type="text"
                    id={this.props.name}
                    ref={this.start_date}
                    className="form-control"
                    autoComplete="off"
                    name={this.props.name}
                    onChange={this.onChange}
                    defaultValue={value}
                    required={this.props.required} />
            </div>
        )
    }

    getMultipleDate = () => {
        const name_start = this.props.name + '_start';
        const name_end = this.props.name + '_end';
        let value = this.props.value;
        if (!(value !== null && Array.isArray(value) === true && value.length >= 2)) {
            value = ['', ''];
        }

        const value_start = value[0];
        const value_end = value[1];

        return (
            <div className="input-daterange input-group" ref={this.container} >
                <span className="input-group-addon" onClick={() => this.focusInput(this.start_date.current)}>
                    <i className="fa fa-calendar"></i>
                </span>
                <input
                    type="text"
                    id={name_start}
                    ref={this.start_date}
                    className="form-control"
                    autoComplete="off"
                    name={name_start}
                    onChange={this.onChange}
                    defaultValue={value_start}
                    required={this.props.required} />
                <span className="input-group-addon px-3">ถึง</span>
                <input
                    type="text"
                    id={name_end}
                    ref={this.end_date}
                    className="form-control"
                    autoComplete="off"
                    name={name_end}
                    onChange={this.onChange}
                    defaultValue={value_end}
                    required={this.props.required} />
            </div>
        )
    }

    render() {
        const is_range = this.props.isRange;
        let date_field;

        if (is_range === true) {
            date_field = this.getMultipleDate();
        } else {
            date_field = this.getSingleDate();
        }

        const value = (this.props.value || '');

        return (
            <div>{date_field}</div>
        )
    }


}

DatePicker.defaultProps = {
    required: false,
    value: '',
    name: '',
    isRange: false,
}

export default DatePicker;