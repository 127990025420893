import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Services } from '../../Service/Services';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';

class InvoiceForm extends Component {
    state = {
        data: {
            customer_name: '',
            customer_id: '',
            customer_address: '',
            invoice_type: 'N',
            invoice_no: '',
            invoice_method: 'A',
            transaction_date: '',
            start_date: '',
            end_date: '',
            select_job_employees: [],
            select_service: [],
            remark: '',
            invoice_month: '',
            invoice_year: '',
        },

        get_invoice: 'invoice',
        flag: true,


        report_link: axios.defaults.baseURL + '/report/invoice/' + this.props.match.params.id,
        report_reserve_link: axios.defaults.baseURL + '/report/reserve/' + this.props.match.params.id,

        datasource: {
            customer_datasource: [],
            invoice_type_datasource: [
                { key: 'N', label: 'ใบแจ้งหนี้ทั่วไป' },
                { key: 'D', label: 'ใบแจ้งหนี้ค่าบริการรับ-ส่งเอกสาร' },
                { key: 'C', label: 'ใบแจ้งหนี้ค่าบริการรถตู้รับ-ส่ง' },
            ],
            service_year_datasource: [],
            job_datasource: [],
        },

        loading: true,
        errors: [],
    }

    getMonthDatasource = () => {
        const months = [
          { key: 1, label: 'มกราคม' },
          { key: 2, label: 'กุมภาพันธ์' },
          { key: 3, label: 'มีนาคม' },
          { key: 4, label: 'เมษายน' },
          { key: 5, label: 'พฤษภาคม' },
          { key: 6, label: 'มิถุนายน' },
          { key: 7, label: 'กรกฎาคม' },
          { key: 8, label: 'สิงหาคม' },
          { key: 9, label: 'กันยายน' },
          { key: 10, label: 'ตุลาคม' },
          { key: 11, label: 'พฤศจิกายน' },
          { key: 12, label: 'ธันวาคม' },
        ];      
  
        return months;
      }
  
      getYearDatasource = () => {
        let date = new Date();
        let year_now = date.getFullYear();
        let years = [];
  
        for(let i = 2019; i <= year_now; i++){
          years.push({
            key: i,
            label: (i + 543),
          })
        }
  
        return years;
      }

    retrieveData = id => {
        if (id !== undefined) {
            const url = (this.props.url + '/' + id);

            axios.get(url).then(res => {
                const job_datasource = res.data.jobs_default;
                const invoice = res.data.invoice;
                const customer_name = res.data.customer_name;
                const customer_datasource = res.data.datasource.customers;
                const customer_address = res.data.customer_address;
                const service_year_datasource = invoice.customer.service_years;

                const remark = invoice.remark;

                const service_year_id_list = invoice.service_year_id_list;

                const select_job_employees = (invoice.invoice_details ? invoice.invoice_details.map(item => item.job_employee_id) : []);

                var select_service = [];
                if (service_year_id_list) {
                    select_service = service_year_id_list.split(',').map(i => parseInt(i));
                }

                const datasource = {
                    ...this.state.datasource,
                    job_datasource,
                    service_year_datasource,
                    customer_datasource
                };

                const data = {
                    id: id,
                    select_job_employees,
                    select_service,
                    invoice_type: invoice.invoice_type,
                    invoice_no: invoice.invoice_no,
                    transaction_date: Services.convertDate(invoice.transaction_date),
                    customer_name: customer_name,
                    customer_address: customer_address,
                    invoice_method: 'A',
                    remark: remark,
                    invoice_month: invoice.invoice_month,
                    invoice_year: invoice.invoice_year,
                };

                this.setState({
                    datasource,
                    data,
                    loading: false,
                });
            });
        } else {
            const data = { ...this.state.data };
            const today = new Date();
            data.transaction_date = (today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear());
            var prevMonth = new Date(today.setMonth(today.getMonth()-1));

            data.invoice_month = prevMonth.getMonth();
            data.invoice_year = prevMonth.getFullYear();

            const params = {
                tables: [
                    { name: 'customer' },
                ],
            }

            axios.post('/dropdown/datasource', params).then(res => {
                const customer_datasource = res.data.customer;

                this.setState({
                    datasource: {
                        ...this.state.datasource,
                        customer_datasource: customer_datasource,
                    },
                    data,
                    loading: false,
                });
            });
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        this.retrieveData(id);
    }

    onSelectJobEmployee = job_employee => event => {
        const data = this.state.data;
        const checked = event.target.checked;
        const job_employee_id = job_employee.id;

        if (checked) {
            data.select_job_employees = [...data.select_job_employees, job_employee_id];
        }
        else {
            data.select_job_employees = data.select_job_employees.filter(select_job_employee_id => {
                return select_job_employee_id !== job_employee_id
            });
        }

        this.setState({ data });
    }

    onSelectService = (service, event) => {
        const data = this.state.data;

        if (event.target.checked) {
            data.select_service = [...data.select_service, service.id];

        }
        else {
            data.select_service = data.select_service.filter(function (select_service) {
                return select_service !== service.id
            });
        }

        this.setState({ data });
    }

    onSubmit = e => {
        e.preventDefault();

        var params = {
            ...this.state.data,
        };

        const url = this.props.url;
        const redirect = true;

        let loading = true;

        this.setState({ loading });

        Services.submitRequest({ params, url }).then(res => {
            if (this.state.data.id) {
                this.retrieveData(this.state.data.id);
            } else {
                const data = {
                    ...this.state.data,
                    id: res.data.id
                };

                loading = false;

                this.setState({ redirect, loading, data });
            }
        }).catch(errors => { this.setState({ errors, loading }) });
    }

    getRedirect = () => {
        const redirect = this.state.redirect;

        if (redirect) {
            const url = ('/transaction/invoice/' + this.state.data.id + '/edit');

            return <Redirect to={url} />;
        }

        return;
    }

    getValue = e => {
        const data = { ...this.state.data, ...e };
        const name = Object.keys(e)[0];

        this.setState({ data });

        if (name === 'invoice_type') {
            this.getDescription(data);
        }
    }

    onChange = e => {

        var { data, datasource } = this.state;
        const { name, value } = e.target;

        data[name] = value;
        if (name === 'customer_id') {
            // data.select_service = [];
            // datasource.service_year_datasource = this.getServiceYearDatasource(datasource.customer_datasource, value);
            const get_customer_url = 'master/customer/' + data.customer_id;
            axios.get(get_customer_url).then(res => {
                data.customer_address = res.data.address + ' ' + res.data.address2;
            });
            this.getDescription();
        }

        this.setState({ data, datasource });
    }

    datepickerCallback = (value) => {
        const data = {
            ...this.state.data,
            ...value,
        }

        this.setState({ data });
    }

    getDescription(data = null) {

        if (this.state.data.customer_id) {

            this.setState({ loading: true });

            const id = this.state.data.id;
            const datasource = this.state.datasource;

            if (data === null) {
                data = this.state.data
            }

            const params = {
                id: id,
                customer_id: data.customer_id,
                invoice_type: data.invoice_type,
            };

            axios.post(this.state.get_invoice, params).then(res => {
                const invoice_detail = res.data.invoice_detail;

                if (invoice_detail.length > 0) {
                    if (data.invoice_type === 'N') {
                        datasource.job_datasource = invoice_detail;
                    } else {
                        datasource.service_year_datasource = invoice_detail;
                    }

                    data.select_job_employees = [];

                    this.setState({
                        datasource,
                        data,
                        loading: false,
                    });
                } else {
                    datasource.job_datasource = [];

                    this.setState({
                        datasource,
                        loading: false,
                    });
                }

            }, error => {
                this.setState({ loading: false });
            });
        }
    }

    selectAll = e => {
        var checked = e.target.checked;

        let data = this.state.data;

        if (checked === true) {
            data.select_job_employees = [];

            this.state.datasource.job_datasource.map(job => {
                return job.job_details.map(job_detail => {
                    return job_detail.job_employees.map(job_employee => {
                        data.select_job_employees.push(job_employee.id);
                    });
                });
            });
        } else {
            data.select_job_employees = [];
        }

        this.setState(data);
    }

    getConditionFields = () => {
        var condition_fields;

        if (this.state.data.invoice_type === "N") {
            var total_price = 0;
            const jobs = this.state.datasource.job_datasource.map((item, index) => {
                const job_no = item.document_no;

                var job_details = [];

                item.job_details.map((job_detail, job_detail_index) => {
                    const transaction_date = Services.convertDate(job_detail.transaction_date);

                    for (var i = 0; i < job_detail.job_employees.length; i++) {
                        const job_employee = job_detail.job_employees[i];
                        // const employee_license_plate = job_employee.employee_license_plate;
                        const employee = job_employee.employee;

                        const driver = employee.name + ' - ' + (job_employee.car_id ? job_employee.car.license_plate : (job_employee.car_text || ''));
                        const bill_price = job_employee.bill_price;

                        const job_expenses = job_employee.job_expenses;

                        var expenses = job_expenses.filter(job_expense => {
                            const expense = job_expense.expense;
                            if(expense.code === 'E004'){
                                if(job_employee.ot){
                                    return true;
                                }else{
                                    return false;
                                }
                            }

                            return job_expense.price !== null;
                        }).map((job_expense, job_expense_index) => {
                            const expense = job_expense.expense;
                            let price = job_expense.price;

                            if(expense.code === 'E004'){
                                price *= job_employee.ot;
                            }

                            return (
                                <span className='mr-1' key={job_expense_index}><strong>{expense.name}:</strong> {price}</span>
                            );
                        })

                        total_price += bill_price;

                        var defaultChecked = (this.state.data.select_job_employees.indexOf(job_employee.id) >= 0)

                        const key = (job_detail_index + "-" + i);

                        job_details.push((
                            <tr key={key}>
                                <td className="text-center"><input type="checkbox" onChange={this.onSelectJobEmployee(job_employee)} checked={defaultChecked} /></td>
                                <td>{transaction_date}</td>
                                <td>{driver}</td>
                                <td className="text-right">{Services.convertToMoney(bill_price)}</td>
                                <td>{expenses}</td>
                            </tr>
                        ));
                    };
                });

                return (
                    <React.Fragment key={index}>
                        <tr className='bg-primary'>
                            <td colSpan="5" className='pl-3'>
                                {job_no}
                            </td>
                        </tr>
                        {job_details}
                    </React.Fragment>
                )
            });

            condition_fields = (
                <div>
                    <div className="form-group">
                        <label className="col-form-label">รายละเอียด:</label>
                        <table className="table checkable">
                            <thead>
                                <tr>
                                    <th><input type="checkbox" className="" onChange={this.selectAll} /></th>
                                    <th>วันที่</th>
                                    <th>คนขับ</th>
                                    <th>ราคาวางบิล</th>
                                    <th>ค่าใช้จ่ายอื่นๆ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {jobs}
                            </tbody>
                            <tfoot>
                                <tr className="bg-secondary text-white">
                                    <td className="text-right" colSpan="3">รวม</td>
                                    <td className="text-right">{Services.convertToMoney(total_price)}</td>
                                    <td className="text-left" colSpan="1">บาท</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            )
        } else {
            const service_year_datasource = this.state.datasource.service_year_datasource.map((item, index) => {
                var checked = false;

                const select_service_item = this.state.data.select_service.indexOf(item.id);
                if (select_service_item !== -1) { checked = true }

                var checkbox = <td className='text-center'><input type="checkbox" className="" checked={checked} onChange={e => this.onSelectService(item, e)} /></td>
                var name = <td>{item.name}</td>
                var price = <td className='text-right'>{Services.convertToMoney(item.price)}</td>

                return <tr key={index}>{checkbox}{name}{price}</tr>
            });

            const month_datasource = this.getMonthDatasource();
            const year_datasource = this.getYearDatasource();

            const invoice_month_field = <FormInput className='col-md-6 col-12' field={{ name: "invoice_month", label: "เดือน", type: "ddl", datasource: month_datasource, value: this.state.data.invoice_month, required: true }} onChange={this.onChange}></FormInput>
            const invoice_year_field = <FormInput className='col-md-6 col-12' field={{ name: "invoice_year", label: "ปี", type: "ddl", datasource: year_datasource, value: this.state.data.invoice_year, required: true }} onChange={this.onChange}></FormInput>

            condition_fields = (
                <React.Fragment>
                    <div className='row'>
                        {invoice_month_field}
                        {invoice_year_field}
                    </div>
                    <div className="form-group">
                        <label className="col-form-label">รายละเอียดบริการ:</label>
                        <table className="table checkable">
                            <thead>
                                <tr>
                                    <th><input type="checkbox" className="" /></th>
                                    <th>บริการ</th>
                                    <th>ราคา</th>
                                </tr>
                            </thead>
                            <tbody>
                                {service_year_datasource}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            )
        }

        return condition_fields;
    }

    getPrintMenuContainer = () => {
        const id = this.state.data.id;

        if (id) {
            var print_menu;

            print_menu = [<li key='1'><a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={this.state.report_link}>ใบแจ้งหนี้</a></li>];
            if (this.state.data.invoice_type === 'N') {
                print_menu.push(<li key='2'><a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={this.state.report_reserve_link}>ใบรับเงิน</a></li>);
            }

            var print_menu_container = (<div className="btn-group">
                <button data-toggle="dropdown" className="btn btn-warning dropdown-toggle mr-2" aria-expanded="true">พิมพ์</button>
                <ul className="dropdown-menu" x-placement="bottom-start">
                    {print_menu}
                </ul>
            </div>)
            return print_menu_container;
        }

        return;
    }

    setRemark = text => {
        this.setState((state) => ({ 
            data: {
                ...state.data,
                remark: text,
            }
        }));
    }

    getFields = () => {
        const id = this.props.match.params.id;

        var customer_field;
        var invoice_type_field;
        var invoice_method;
        var label_customer_address;
        var remark;
        if (id) {
            var invoice_type_value = this.state.datasource.invoice_type_datasource.filter(f => f.key === this.state.data.invoice_type)[0].label;

            customer_field = <FormInput field={{ name: "customer_name", label: "ลูกค้า", type: "label", value: this.state.data.customer_name }}></FormInput>
            invoice_type_field = <FormInput field={{ name: "invoice_type", label: "ประเภทใบแจ้งหนี้", type: "label", value: invoice_type_value }}></FormInput>
            label_customer_address = <FormInput field={{ name: "customer_address", label: "ที่อยู่ลูกค้า", type: "label", value: this.state.data.customer_address }}></FormInput>


            remark = <FormInput field={{ name: "remark", label: "หมายเหตุ", type: "textarea", value: this.state.data.remark }} getValue={this.getValue}></FormInput>
        } else {
            customer_field = <FormInput field={{ name: "customer_id", label: "ลูกค้า", type: "ddl", datasource: this.state.datasource.customer_datasource, value: this.state.data.customer_id, required: true }} onChange={this.onChange}></FormInput>
            invoice_type_field = <FormInput field={{ name: "invoice_type", label: "ประเภทใบแจ้งหนี้", type: "radio", datasource: this.state.datasource.invoice_type_datasource, value: this.state.data.invoice_type, required: true }} getValue={this.getValue}></FormInput>
            // invoice_method = <FormInput field={{ name: "invoice_method", label: "การคิดเงิน", type: "radio", value: this.state.data.invoice_method, datasource: this.state.datasource.invoice_method_datasource }} getValue={this.getValue}></FormInput>
            label_customer_address = <FormInput field={{ name: "customer_address", label: "ที่อยู่ลูกค้า", type: "label", value: this.state.data.customer_address }}></FormInput>

            remark = <FormInput field={{ name: "remark", label: "หมายเหตุ", type: "textarea", value: this.state.data.remark }} getValue={this.getValue}></FormInput>
        }

        return (<div>{customer_field}{label_customer_address}{invoice_type_field}{invoice_method}{remark}</div>);
    }

    render() {
        const redirect = this.getRedirect();
        const condition_fields = this.getConditionFields();
        const print_menu_container = this.getPrintMenuContainer();
        const fields = this.getFields();
        const url = this.props.url;

        return (
            <BoxContainer loading={this.state.loading} errors={this.state.errors}>
                {redirect}
                <form onSubmit={this.onSubmit}>
                    <div className="form-group row">
                        <div className="col-12 text-right">
                            {print_menu_container}
                            <button className="btn btn-primary mr-2" type="submit" >บันทึก</button>
                            <Link className="btn btn-white" to={url}>ย้อนกลับ</Link>
                        </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                        <label className="col-form-label">เลขที่ใบแจ้งหนี้:</label> {this.state.data.invoice_no}
                    </div>
                    <FormInput field={{ name: "transaction_date", label: "วันที่", type: "datepicker", required: true, value: this.state.data.transaction_date }} datepickerCallback={this.datepickerCallback}></FormInput>

                    {fields}
                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                        <button type="button" className="btn btn-secondary" onClick={() => this.setRemark('ค่าบริการหักภาษี 3%')}>ค่าบริการหักภาษี 3%</button>
                    </div>

                    {condition_fields}

                    <div className="hr-line-dashed"></div>
                    <div className="form-group row">
                        <div className="col-12 text-right">
                            {print_menu_container}
                            <button className="btn btn-primary mr-2" type="submit" >บันทึก</button>
                            <Link className="btn btn-white" to={url}>ย้อนกลับ</Link>
                        </div>
                    </div>
                </form>
            </BoxContainer>
        )
    }
}

export default InvoiceForm
