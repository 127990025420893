import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import FormInput from '../../../components/DataForm/FormInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import axios from '../../../axios';

class JobReport extends Component {
    state = {
        transaction_date_start: '',
        transaction_date_end: '',
        transaction_date: [],
    }

    datepickerCallback = value => {
        let new_state = {
            ...this.state,
            ...value
        };

        new_state.transaction_date = [ new_state.transaction_date_start, new_state.transaction_date_end ];
        
        this.setState(new_state);
    }

    componentDidMount = () => {

    }

    printJobBySelect = e => {
        e.preventDefault();

        console.log("Print", this.props.list_id);
        
        const list_id = this.props.list_id;

        if(list_id.length > 0){
            const params = [
                [ 'list_id', list_id ],
            ];

            var url = axios.defaults.baseURL + '/report/car?' + params.map( i => (i[0] + '=' + i[1])).join('&');

            console.log(url);

            window.open(url, '_blank');
        }
    }

    onSubmit = e => {
        e.preventDefault();
        
        // Print Report
        const params = [
            [ 'transaction_date_start', this.state.transaction_date_start ],
            [ 'transaction_date_end', this.state.transaction_date_end ],
        ];

        var url = axios.defaults.baseURL + '/report/car?' + params.map( i => (i[0] + '=' + i[1])).join('&');

        window.open(url, '_blank');
    }

    render() {
        return (
            <div className='text-left'>
                <div className="dropdown">
                  <button className="ml-3 btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='fa fa-print'></i> พิมพ์รายงาน
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#" data-toggle="modal" data-target="#reportModal">พิมพ์ตามเงื่อนไข</a>
                    <a className="dropdown-item" href="#" onClick={this.printJobBySelect}>พิมพ์ที่เลือก</a>
                  </div>
                </div>

            	<div className="modal fade" id="reportModal" role="dialog" aria-labelledby="reportModal" aria-hidden="true">
            	  <div className="modal-dialog" role="document">
            	    <div className="modal-content">
            	      <div className="modal-header bg-primary text-white">
            	        <h5 className="modal-title" id="reportModal">รายงาน</h5>
            	        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            	          <span aria-hidden="true">&times;</span>
            	        </button>
            	      </div>
            	      <form onSubmit={this.onSubmit}>
            	          <div className="modal-body">
                                <FormInput 
                                    field={{ 
                                        type: 'datepicker', 
                                        name: 'transaction_date', 
                                        isRange: true,
                                        label: 'วันที่เริ่มต้น - วันที่สิ้นสุด', 
                                        value: this.state.transaction_date,
                                    }} 
                                datepickerCallback={this.datepickerCallback}></FormInput>
            	          </div>
            	          <div className="modal-footer">
            	            <button type="button" className="btn btn-secondary" data-dismiss="modal">ปิด</button>
            	            <button type="submit" className="btn btn-primary">พิมพ์รายงาน</button>
            	          </div>
            	      </form>
            	    </div>
            	  </div>
            	</div>
            </div>
        )
    }
}

JobReport.defaultProps = {
    list_id: [],
}

export default JobReport