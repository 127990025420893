import React, { Component } from 'react'
// import DataForm from './../DataForm/DataForm';
import FormInput from '../DataForm/FormInput';

class TableForm extends Component {
  state = {
    items: [

    ],
  }

  add_item = () => {
    const items = this.state.items;
    items.push(this.get_default_item());

    // this.setState({ items });
    const return_value = {[this.props.name]: items.map( i => this.convert_to_return_value(i)) };

    this.props.getValue(return_value);
  }

  convert_to_return_value = item => {
    return {
      id: item.id,
      ...item.value,
    }
  }

  get_default_item = () => {
    var default_item = {
      id: null, 
      value: {}
    }

    this.props.controls.map( item => { 
      const default_value = item.default_value || '';
      default_item.value[item.name] = default_value;
    })

    return default_item;
  }

  remove_item = index => {
    const items = this.state.items;
    items.splice(index, 1);

    // this.setState({ items });
    const return_value = {[this.props.name]: items.map( i => this.convert_to_return_value(i)) };

    this.props.getValue(return_value);
  }

  onChange = (e, index, key) => {
    // console.log(e, index, key);
    const items = this.state.items;
    const value = e[key];

    items[index].value[key] = value;

    const return_value = {[this.props.name]: items.map( i => this.convert_to_return_value(i)) };

    this.props.getValue(return_value);
  }

  componentDidUpdate = () => {
    if(this.state.items.length !== this.props.value.length){
        // re-render
        this.setState({
          items: this.props.value.map( (value, index) => {
            var item = {
              id: value.id,
              value: {}
            }

            for(var key in value){
              if(key !== 'id'){
                item.value[key] = value[key];
              }
            }

            return item;
          })
        });
      }
  }

  render() {
    var headers = this.props.controls.map( (item, index) => <th key={index}>{item.label}</th> );
    var items = this.state.items.map( (item, index) => {
                  const child_params = [];
                  
                  for(var item_key in item.value){
                    const control = this.props.controls.filter( control => (control.name === item_key) )[0];

                    var type = control.type
                    
                    if(type === undefined) type = 'textbox';

                    var value = item.value[item_key];

                    if(value === undefined || value === null) value = '';

                    child_params.push({name: item_key, value: value, type});
                  }

                  var child = child_params.map( (child, child_index) => {
                    let field = child;

                    return (
                      <td key={child_index} className='text-center'>
                        <FormInput field={field}
                            getValue={ e => { this.onChange(e, index, child.name) }}
                        ></FormInput>
                      </td> 
                    );
                  });

                  var trash = (<td className='text-center'>
                    <button onClick={() => { this.remove_item(index) }} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-light btn-sm ml-1">
                        <i className="fa fa-trash"></i>
                    </button>
                  </td>)
                  return <tr key={index}>{child}{trash}</tr>
    });

    return (
      <div>
        <label className="col-form-label">{this.props.label}</label>
        <button onClick={this.add_item} type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-light btn-sm ml-1 float-right">
            <i className="fa fa-plus"></i>
        </button>
        <table className="table table-bordered">
          <thead>
            <tr>
              {headers}
              <th># Action</th>
            </tr>
          </thead>
          <tbody>
              {items}
          </tbody>
        </table>
      </div>
    )
  }
}

TableForm.defaultProps = {
  controls: [], // { name: '', label: '', value: '', type: '' }
  label: '',
  name: '',
  value: [],
  getValue: {},
}

export default TableForm
