import React, { Component } from 'react';
// import logo from '../assets/logo.svg';
// import { Router, Route } from 'react-router-dom';
import { Router, Route, Switch } from "react-router-dom";
// import { IndexRoute } from 'react-router'
import './App.css';
import Sidebar from './Sidebar/Sidebar';
import Navbar from './Navbar/Navbar';
import Dashboard from '../pages/Dashboard/Dashboard';
import SetupUser from '../pages/Master/SetupUser/SetupUser';
import SetupEmployee from '../pages/Master/SetupEmployee/SetupEmployee';
import SetupCustomer from '../pages/Master/SetupCustomer/SetupCustomer';
import SetupSetting from '../pages/Master/SetupSetting/SetupSetting';
import SetupCar from '../pages/Master/SetupCar/SetupCar';

import Job from '../pages/Transaction/Job/Job';
import JobWizard from '../pages/Transaction/Job/JobWizard';
import Invoice from '../pages/Transaction/Invoice/Invoice';
import Expense from '../pages/Transaction/Expense/Expense';
import Receipt from '../pages/Transaction/Receipt/Receipt';

import Login from '../pages/Authentication/Login/Login';
import Forgotpassword from '../pages/Authentication/Forgotpassword/forgotpassword';
// import Activated from '../pages/Authentication/Activated/Activated';
import Changepassword from '../pages/Authentication/Chagepassword/changepassword';
import Profile from '../pages/Profile/profile';
import editPassword from '../pages/Profile/changepassword';
import Notfound from '../pages/Authentication/Service/404';
import ReportTax from '../pages/Report/ReportTax/ReportTax';
import ReportJob from '../pages/Report/ReportJob/ReportJob';
import ReportInvoice from '../pages/Report/ReportInvoice/ReportInvoice';
import ReportReceipt from '../pages/Report/ReportReceipt/ReportReceipt';

// import Sample from '../pages/sample';
import { authenticationService } from '../pages/Authentication/Service/authentication.service';
import { PrivateRoute } from './PrivateRoute';
import { history } from '../pages/Authentication/_helpers/';
const routes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/profile",
    exact: true,
    component: Profile,
  },
  {
    path: "/profile/changepassword",
    component: editPassword,
  },
  {
    path: "/master/user",
    component: SetupUser,
  },
  {
    path: "/master/employee",
    component: SetupEmployee,
  },
  {
    path: "/master/customer",
    component: SetupCustomer,
  },
  {
    path: "/master/setting",
    component: SetupSetting,
  },
  {
    path: "/master/car",
    component: SetupCar,
  },
  {
    path: "/transaction/job",
    component: Job,
  },
  {
    path: "/transaction/job_wizard",
    component: JobWizard,
  },
  {
    path: "/transaction/invoice",
    component: Invoice,
  },
  {
    path: "/transaction/expense",
    component: Expense,
  },
  {
    path: "/transaction/receipt",
    component: Receipt,
  },
  {
    path: "/report/tax",
    component: ReportTax,
  },
  {
    path: "/report/job",
    component: ReportJob,
  },
  {
    path: "/report/job_invoice",
    component: ReportInvoice,
  },
  {
    path: "/report/invoice_receipt",
    component: ReportReceipt,
  },
  {
    path: "/forgotpassword",
    component: Forgotpassword,
  },
  {
    path: "/changepassword/token=:token",
    component: Changepassword,
  },
];
const routes_without_theme = [
  {
    path: "/",
    exact: true,
    component: Login,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "/forgotpassword",
    component: Forgotpassword,
    // exact: true,
  },
  {
    path: "/changepassword/token=:token",
    component: Changepassword,
  },
  {
    path: "profile/",
    component: Profile,
  },
  {
    // path: '*',
    component: Notfound,
    // exact: true,
  },
];
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };
  }
  componentDidMount() {
    authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
  }

  // logout() {
  //   authenticationService.logout();
  //   history.push('/login');
  // }

  render() {
    const { currentUser } = this.state;
    var container;
    if (currentUser) {
      const menu = routes.map((route, index) =>
        <PrivateRoute key={index} path={route.path} exact={route.exact} component={route.component} />
      )

      container = <div id="wrapper">
        <Sidebar></Sidebar>
        <div id="page-wrapper" className="gray-bg">
          <div className="row border-bottom">
            <Navbar></Navbar>
          </div>
          <Switch>
            {menu}
          </Switch>
          <div className="footer">
            <strong>Copyright</strong> Sirichat Service &copy; 2019
                      </div>
        </div>
      </div>

      return (
        <Router history={history}>
          {container}
        </Router>
      )

    } else {
      container = routes_without_theme.map((route, index) =>
        <Route key={index} path={route.path} exact={route.exact} component={route.component} />
      )

      return (
        <Router history={history}>
          <Switch>
            {container}
          </Switch>
        </Router>
      )
    }
  }
}
export default App;
