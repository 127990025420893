import React, { Component } from 'react'
import axios from '../../axios';
import './DataTable.css';
import SearchCriteria from './SearchCriteria';
import SearchResult from './SearchResult';
import SearchTool from './SearchTool';
import Pagination from './../Pagination/Pagination';
import TitleHeader from './../TitleHeader/TitleHeader';
const $ = window.$;

class DataTable extends Component {
    state = {
        data: [
            // { key: 1, data: ['mr.saend@gmail.com', 'Morrakot Income', 'Active'] },
            // { key: 2, data: ['whitepen_pj@hotmail.com', 'Morrakot2 Income', 'Pending'] },
        ],
        loading: true,
        criteria: {
            order_by: '',
            records_per_page: 10,
            page_number: 1,
            keyword: '',
        },
        total_pages: 1,
        total_records: 0,
        page_status: false,
    }

    changeRecordPerPage = records_per_page => {
        const params = {
            ...this.state.criteria,
            records_per_page
        }

        this.search(params);
    }

    changePage = page_number => {
        const params = {
            ...this.state.criteria,
            page_number
        }

        this.search(params);
    }

    getStartRowRunning = () => {
        let start_running = ((this.state.criteria.page_number - 1) * this.state.criteria.records_per_page);

        return start_running + 1;
    }

    getEndRowRunning = () => {
        let start_row_running = this.getStartRowRunning();
        let end_running = (parseInt(start_row_running) + parseInt(this.state.criteria.records_per_page)) - 1;

        if (this.state.criteria.records_per_page === 0 || end_running > this.state.total_records) return this.state.total_records;
        else return end_running;
    }

    search = (params = {}) => {
        var default_params = {
            keyword: this.state.criteria.keyword,
            order_by: this.state.criteria.order_by, // [column_name],[direction]
            records_per_page: this.state.criteria.records_per_page,
            page_number: params.page_number ? params.page_number : this.state.criteria.page_number,
            filter: {},
        };

        $.extend(true, default_params, params);

        if (this.props.url !== undefined && this.props.url !== '') {
            axios.get(this.props.url, { params: default_params }).then(res => {
                const total_records = parseInt(res.headers.total_records);
                let total_pages = 1;
                if (default_params.records_per_page === 0 || total_records === 0) {
                    total_pages = 1;
                } else {
                    total_pages = Math.ceil(total_records / default_params.records_per_page);
                }

                const new_state = {
                    data: res.data,
                    loading: false,
                    criteria: default_params,
                    total_pages,
                    total_records,
                    page_status: true,
                };

                this.setState(new_state);
            })
        } else {
            const total_records = this.props.datasource.length;
            let total_pages = 1;

            const new_state = {
                data: this.props.datasource,
                loading: false,
                criteria: default_params,
                total_pages,
                total_records,
                page_status: true,
            };

            this.setState(new_state);
        }
    }

    componentDidMount() {
        // console.log('componentDidMount');
        const params = {
            ...this.state.criteria,
        }
        this.search(params);
    }

    componentDidUpdate() {
        // console.log('componentDidUpdate');
    }

    onPrintMultipleClick = () => {
        this.props.onPrintClick(this.state.criteria);
    }

    render() {
        const filter = this.props.columns.filter( item => (item.search_type !== undefined && item.search_type !== '') || (item.search_field) ).map( item => {
            return item;
        });

        return (
            <div>
                <div className="row wrapper wrapper-content animated fadeInRight">
                    {
                        this.state.page_status ?

                            <div className='col-12'>
                                <div className='ibox'>
                                    <div className='ibox-content'>
                                        <div className='mb-2'>
                                            <SearchCriteria
                                                search={this.search}
                                                url={this.props.url}
                                                filter={filter}
                                            ></SearchCriteria>
                                        </div>
                                        <SearchTool
                                            changeRecordPerPage={this.changeRecordPerPage}
                                            allow_insert={this.props.allow_insert}
                                            allow_print={this.props.allow_print}
                                            print_multiple={this.props.print_multiple}
                                            onPrintClick={this.onPrintMultipleClick}
                                            buttons={this.props.buttons}
                                        ></SearchTool>
                                        <SearchResult
                                            columns={this.props.columns}
                                            loading={this.state.loading}
                                            data={this.state.data}
                                            url={this.props.url}
                                            search={this.search}
                                            allow_active={this.props.allow_active}
                                            allow_insert={this.props.allow_insert}
                                            allow_edit={this.props.allow_edit}
                                            allow_trash={this.props.allow_trash}
                                            criteria={this.state.criteria}
                                            allow_print={this.props.allow_print}
                                            onPrintClick={this.props.onPrintClick}
                                            allow_action={this.props.allow_action}
                                            allow_checkbox={this.props.allow_checkbox}
                                            onCheckClick={this.props.onCheckClick}
                                        >
                                        </SearchResult>
                                        <div>
                                            <div className="pull-right">
                                                <Pagination totalPages={this.state.total_pages} onPageChange={this.changePage} currentPage={this.state.criteria.page_number}></Pagination>
                                            </div>
                                            <div className="pull-left">Showing {this.getStartRowRunning()} to {this.getEndRowRunning()} of {this.state.total_records} records</div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            : null
                    }
                </div>
            </div>
        )
    }
}

DataTable.defaultProps = {
    columns: [
        { label: 'column #1', align: 'left', name: 'col1', search_type: 'string' }, // for advance search use search_type = 'string', 'datepicker', 'checkbox', 'ddl' <-- request ddl_source
        { label: 'column #2', align: 'right', name: 'col2', search_type: 'ddl', ddl_source: [{ key: 1, label: 'col2.1' }, { key: 2, label: 'col2.2' }] },
        { label: 'column #3', align: 'center', name: 'col3' },
        { label: 'column #4', name: 'col1' }, // default align = left
    ],
    url: '', // API Url
    datasource: [],
    allow_active: false,
    allow_print: false,
    allow_insert: true,
    allow_edit: true,
    allow_trash: true,
    print_multiple: false,
    allow_action: true,
    allow_checkbox: false,
    onPrintClick: [],
    onCheckClick: [],
    buttons: [],
};

export default DataTable
