import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import SetupEmployeeForm from './SetupEmployeeForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

class SetupEmployee extends Component {
    render() {
        const columns = [
            { label: 'ชื่อ', align: 'left', name: 'name', search_type: 'string' },
            { label: 'เบอร์โทร', align: 'left', name: 'telephone', search_type: 'string' },
        ];

        const api_url = '/master/employee';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ข้อมูลพนักงาน' program_type='Master'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable columns={columns} url={api_url} allow_active={true}></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<SetupEmployeeForm {...props} url={api_url}></SetupEmployeeForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<SetupEmployeeForm {...props} url={api_url}></SetupEmployeeForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default SetupEmployee
