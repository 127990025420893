import React, { Component } from 'react'
import swal from 'sweetalert';
import { Services } from '../../Service/Services';
import FormInput from './../../../components/DataForm/FormInput';
import InputNumber from '../../../components/InputNumber/InputNumber';
import axios from '../../../axios';


class JobDetailRow extends Component {
	datepickerCallback = value => {
		this.props.onChange(value);
	}

	// timepickerCallback = (value) => { this.handleChange(value); }
	onChange = e => {
		const target_name = e.target.name;
		const value = e.target.value;

		const new_items = { [target_name]: value };

		this.props.onChange(new_items);
	}

	onEmployeeChange = (e, employee_index) => {
		const target_name = e.target.name;
		const value = e.target.value;

		const new_items = { [target_name]: value };
		let new_value = { ...this.props.value };

		new_value.employees[employee_index][target_name] = value;

		this.props.onChange(new_value);
	}

	onExpenseChange = (e, employee_index, expense_index) => {
		const value = e.target.value;

		let new_value = { ...this.props.value };
		const expenses = new_value.employees[employee_index].expenses;

		if (expenses !== undefined && expenses.length > 0) {
			expenses[expense_index].price = value;
		}
		this.props.onChange(new_value);
	}

	getExpenseValue = (employee_index, expense_index) => {
		const expenses = this.props.value.employees[employee_index].expenses;

		if (expenses !== undefined && expenses.length > 0) {
			const expense = expenses[expense_index];
			if (expense !== undefined) {
				return (expense.price ? expense.price : '');
			}
		}
		// console.log(expenses)
		return '';
	}


	// getExpenseLabel = expense_id => {
	// 	const expenses = this.props.expense_datasource.filter( item => item.key === expense_id );

	// 	if(expenses.length > 0) return expenses[0].label;

	// 	return '';
	// }

	onDelete = (e) => {
		this.props.onDelete(e);
	}

	getExpenseControl(employee_index) {
		const expenses = this.props.expense_datasource.map((expense, expense_index) => {
			const value = this.getExpenseValue(employee_index, expense_index);
			const placeholder = expense.label;

			return (
				<div className="input-group mb-1" key={expense_index}>
				  <div className="input-group-prepend">
				    <span className="bg-primary text-white input-group-text" id={"expense_" + expense_index}>{placeholder}</span>
				  </div>
				  <input type='number' className='form-control' name='expense' id={"expense_" + expense_index} value={value} onChange={e => this.onExpenseChange(e, employee_index, expense_index)} />
				</div>
			)
		});

		return expenses;
	}

	getJobEmployeeControl() {
		const value = this.props.value;

		const job_employees = value.employees.map((employee, employee_index) => {
			const job_expenses = this.getExpenseControl(employee_index);
			const bill_price = (employee.bill_price ? employee.bill_price : '');
			const ot = (employee.ot ? employee.ot : '');;

			return (
				<React.Fragment key={employee_index}>
					<tr>
						<td colSpan="9">
							<i className="fa fa-user"></i> {employee.name}
						</td>
					</tr>
					<tr>
						<td>
							<input placeholder='เวลาเริ่มต้น' type="text" className="form-control form-control-sm" name='start_time' value={employee.start_time} onChange={e => this.onEmployeeChange(e, employee_index)} />
							<input placeholder='เวลาสิ้นสุด' type="text" className="form-control form-control-sm mt-1" name='end_time' value={employee.end_time} onChange={e => this.onEmployeeChange(e, employee_index)} />
						</td>
						<td>
							<input placeholder='กิโลเริ่มต้น' type="text" className="form-control form-control-sm" name='start_kilo' value={employee.start_kilo} onChange={e => this.onEmployeeChange(e, employee_index)} />
							<input placeholder='กิโลสิ้นสุด' type="text" className="form-control form-control-sm mt-1" name='end_kilo' value={employee.end_kilo} onChange={e => this.onEmployeeChange(e, employee_index)} />
						</td>
						<td>
							<InputNumber placeholder='ค่ารถ' name='bill_price' value={bill_price} onChange={e => this.onEmployeeChange(e, employee_index)}></InputNumber>
						</td>
						<td>
							<InputNumber placeholder='ชม. OT' name='ot' value={ot} onChange={e => this.onEmployeeChange(e, employee_index)}></InputNumber>
						</td>
						<td>
							{job_expenses}
						</td>
					</tr>
				</React.Fragment>
			);
		});

		return job_employees;
	}

	render = () => {
		const value = this.props.value;
		const expense_datasource = this.props.expense_datasource;
		const job_employees = this.getJobEmployeeControl();
		// const sum_cost = value.employees.reduce((sum, employee) => (sum + Services.convertToDecimal(employee.cost_price)), 0);
		const sum_bill = value.employees.reduce((sum, employee) => (sum + Services.convertToDecimal(employee.bill_price)), 0);
		const job_expenses = [];
		const province_datasource = this.props.province_datasource;

		value.employees.map(employee => employee.expenses.map(job_expense => job_expenses.push(job_expense)));

		const sum_expense = expense_datasource.map((expense, expense_index) => {
			const expense_id = expense.key;
			const expense_sum = job_expenses.filter(f => f.expense_id === expense_id).reduce((sum, job_expense) => (sum + Services.convertToDecimal(job_expense.price)), 0);

			return <li key={expense_index}>{expense.label}: {Services.convertToMoney(expense_sum)}</li>
		});

		console.log(value);

		return (
			<React.Fragment>
				<tr>
					<td>
						<FormInput field={{ value: value.transaction_date, name: "transaction_date", type: "datepicker", required: true }} datepickerCallback={this.datepickerCallback}></FormInput>
					</td>
					<td>
						<FormInput field={{ value: value.province_id, name: "province_id", type: "ddl", datasource: province_datasource }} onChange={this.onChange}></FormInput>
					</td>
					<td>
						<input type='text' className='form-control form-control-sm' placeholder="ไป" onChange={this.onChange} name='source_place' value={value.source_place} />
						<input type='text' className='form-control form-control-sm mt-1' placeholder="กลับ" onChange={this.onChange} name='dest_place' value={value.dest_place} />
					</td>
					<td className='text-right'>{Services.convertToMoney(sum_bill)}</td>
					<td><ul className='list-unstyled'>{sum_expense}</ul></td>
					<td className="text-center">
						<button type="button" title="Trash" className="btn-command btn btn-danger btn-sm" onClick={this.onDelete}><i className="fa fa-trash"></i> ลบ</button>
					</td>
				</tr>
				<tr className='border-bottom'>
					<td colSpan="6">
						<table className="table main-table table-borderless">
							<thead>
								<tr>
									<th>เวลา</th>
									<th>กิโลเมตร</th>
									<th>ค่ารถ</th>
									<th>ชม. OT</th>
									<th>ค่าใช้จ่ายอื่นๆ</th>
								</tr>
							</thead>
							<tbody>
								{job_employees}
							</tbody>
						</table>
					</td>
				</tr>
			</React.Fragment>
		)
	}

}

JobDetailRow.defaultProps = {
	value: {},
	expense_datasource: [],
	province_datasource: [],
}

export default JobDetailRow