import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Services } from '../../Service/Services';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import InputNumber from '../../../components/InputNumber/InputNumber';
import TableForm from '../../../components/TableForm/TableForm';
import LOV from '../../../components/LOV/LOV';
import JobDetailForm from './JobDetailForm';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
import clipboard from 'clipboard';

const $ = window.$;

class JobForm extends Component {
    constructor(params) {
        super(params);

        this.btnCopyElement = React.createRef();
    }

    state = {
        car_list_id: [],
        car_list_id_outsource: [],
        employee_api: {
            url: '/lov/employees',
            params: {}, //{ jobs: [ { transaction_date: '', start_time: '', end_time: '' } ] },
        },
        data: {
            employees: [],
            customer_id: '',
            province_id: '',
            contact_customer_id: '',
            job_ref_no: '',
            requester: '',
            remark: '',
            job_details: [],
            shareable_report_link: '',
        },
        report_car_link: (axios.defaults.baseURL + '/report/car/' + this.props.match.params.id),
        datasource: {},
        payment_btn: false,
        loading: true,
        errors: [],
    }

    paymentClick = () => {
        swal({
            title: "Warning",
            text: 'คุณแน่ใจหรือไม่ที่จะให้รายการนี้เป็นรายการที่ถูกชำระแล้ว',
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((will) => {
            if (will) {
                axios.post('/job/payment', {
                    id: this.props.match.params.id,
                    payment: true,
                }).then(res => {
                    swal({
                        title: "Success",
                        text: 'บันทึกข้อมูลเสร็จสิ้น',
                        icon: "success",
                        button: "OK",
                    });

                    // Services.response(res)
                });
            }
        });
    }

    componentDidMount = e => {
        // $('input.number').keyup(function (event) {
        //     // skip for arrow keys
        //     if (event.which >= 37 && event.which <= 40) return;

        //     // format number
        //     $(this).val(function (index, value) {
        //         return value
        //             .replace(/\D/g, "")
        //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        //             ;
        //     });
        // });

    }

    retrieveData = (id) => {
        if (id !== undefined) {
            const url = (this.props.url + '/' + id);
            axios.get(url).then(res => {
                const job = res.data.job;
                const customer_datasource = res.data.datasource.customers;
                const contact_customer_datasource = this.getContactCustomerDatasource(job.customer_id, customer_datasource);
                const province_datasource = res.data.datasource.provinces;
                const expense_datasource = res.data.datasource.expenses;
                const car_datasource = res.data.datasource.car
                const job_details = job.job_details.map(item => {
                    const transaction_date = Services.convertDate(item.transaction_date);

                    const job_detail = {
                        id: item.id,
                        source_place: item.source_place,
                        dest_place: item.dest_place,
                        transaction_date,
                        province_id: item.province_id,
                    }
                    job_detail.employees = item.job_employees.map(job_employee => {
                        const employee = job_employee.employee;
                        const name = employee.name;
                        const expenses = job_employee.job_expenses;

                        return {
                            ...job_employee,
                            expenses,
                            name,
                        }
                    });

                    return job_detail;
                });

                const employees = res.data.employees;

                const data = {
                    payment: job.payment,
                    invoice: job.invoice,
                    document_no: job.document_no,
                    customer_id: job.customer_id,
                    province_id: job.province_id,
                    contact_customer_id: job.contact_customer_id,
                    job_ref_no: (job.job_ref_no !== null ? job.job_ref_no : ''),
                    requester: (job.requester !== null ? job.requester : ''),
                    remark: (job.remark !== null ? job.remark : ''),
                    status: job.status,
                    job_details: job_details,
                    employees: employees,
                    id,
                    shareable_report_link: res.data.shareable_report_link,
                }

                this.setState({
                    datasource: {
                        customer_datasource: customer_datasource,
                        province_datasource: province_datasource,
                        expense_datasource: expense_datasource,
                        contact_customer_datasource: contact_customer_datasource,
                        car_datasource: car_datasource
                    },

                    data,
                    payment_btn: res.data.paymentable,
                    loading: false,
                });
            });
        } else {
            const params = {
                tables: [
                    { name: 'customer' },
                    { name: 'province' },
                    { name: 'expense' },
                    { name: 'car' }
                ]
            }

            axios.post('/dropdown/datasource', params).then(res => {
                const customer_datasource = res.data.customer;
                const province_datasource = res.data.province;
                const expense_datasource = res.data.expense;
                const car_datasource = res.data.car;
                this.setState({
                    datasource: {
                        customer_datasource: customer_datasource,
                        province_datasource: province_datasource,
                        expense_datasource: expense_datasource,
                        car_datasource: car_datasource
                    },
                    loading: false,
                });
            });
        }
    }

    componentDidMount = () => {
        const id = this.props.match.params.id;

        this.setState({ loading: true });
        this.retrieveData(id);

        new clipboard(this.btnCopyElement.current);
    }

    onSubmit = e => {
        e.preventDefault();

        const data = { ...this.state.data };

        const job_details = data.job_details.map((item) => {

            const employees = item.employees.map((employee, index) => {
                const emp = data.employees[index];
                const employee_id = emp.id;
                const car_id = emp.value.car_id ? emp.value.car_id : null;
                const car_text = emp.value.car_text ? emp.value.car_text : null;

                return {
                    ...employee,
                    employee_id,
                    car_id,
                    car_text,
                }
            });

            return {
                employees: employees,
                dest_place: item.dest_place,
                id: item.id,
                source_place: item.source_place,
                transaction_date: item.transaction_date,
                province_id: item.province_id,
            }
        });
        const params = {
            contact_customer_id: data.contact_customer_id,
            customer_id: data.customer_id,
            job_details: job_details,
            job_ref_no: data.job_ref_no,
            province_id: data.province_id,
            remark: data.remark,
            requester: data.requester,
            id: this.state.data.id,
        };

        const url = this.props.url;
        const redirect = true;

        let loading = true;

        this.setState({ loading });

        Services.submitRequest({ params, url }).then(res => {
            if (this.state.data.id) {
                this.retrieveData(this.state.data.id);
            } else {
                const data = {
                    ...this.state.data,
                    id: res.data.id
                };

                loading = false;

                this.setState({ redirect, loading, data });
            }
        }).catch(errors => {
            loading = false;
            this.setState({ errors, loading })
        });
    }

    onChange = (event) => {
        const { name, value } = event.target;

        var data = this.state.data;
        data[name] = value;

        if (name === 'customer_id') {
            const contact_customer_datasource = this.getContactCustomerDatasource(value)

            var datasource = this.state.datasource;

            data.contact_customer_id = '';
            datasource.contact_customer_datasource = contact_customer_datasource;

            this.setState({
                data, datasource
            });
        } else {
            if(name === 'province_id'){
                // let job_details = this.state.data.job_details.map( item => {
                //     item.province_id = value;
                //     return item;
                // });

                // data.job_details = job_details;
            }

            this.setState({
                data
            });
        }
    }

    getContactCustomerDatasource = (customer_id, customer_datasource = null) => {

        customer_datasource = (customer_datasource === null ? this.state.datasource.customer_datasource : customer_datasource);
        const customers = customer_datasource.filter(f => f.key == customer_id);

        if (customers.length > 0) {

            const contact_customer = customers[0].contact_customers;

            return contact_customer;
        }

        return [];
    }

    getValueJobDetail = job_details => {
        let { data, employee_api } = this.state;
        employee_api.params = { job_details }
        data.job_details = job_details;

        this.setState({ data, employee_api });
    }

    onEmpolyeeChange = lov_value => {
        let data = this.state.data;
        const filters = data.employees.filter(item => item.id === lov_value.employee.key);

        if (filters.length === 0) {
            const employee = {
                id: lov_value.employee.key,
                value: lov_value.employee.values,
            }
            data.employees.push(employee);

            // data.job_details = data.job_details.map( item => {
            //     const employee = {
            //         employee_id: employee_license_plate.id,
            //         name: employee_license_plate.value.name,
            //         license_plate: employee_license_plate.value.license_plate,
            //     };

            //     item.employees.push(employee);

            //     return item;
            // });

            this.setState({ data });
        }
    }

    deleteEmployee = index => {
        let data = this.state.data;

        data.employees.splice(index, 1);

        this.setState({ data });
    }

    getRedirect = () => {
        const redirect = this.state.redirect;

        if (redirect) {
            const url = ('/transaction/job/' + this.state.data.id + '/edit');

            return <Redirect to={url} />;
        }

        return;
    }
    getValue = (value, index) => {
        var employees = this.state.data.employees;
        employees[index].value.car_text = value.car_text;
        this.setState({ employees });
    }
    onChangeCar = (value, index) => {
        var employees = this.state.data.employees;
        employees[index].value.car_id = value.value;
        this.setState({ employees });
        // if (is_outsource === false) {
        //     const car_list_id = this.state.car_list_id;
        //     car_list_id[index] = value;
        //     this.setState({ car_list_id });
        // } else {
        //     const car_list_id_outsource = this.state.car_list_id_outsource;
        //     car_list_id_outsource[index] = value;
        //     this.setState({ car_list_id_outsource });
        // }
    }
    render() {
        const redirect = this.getRedirect();
        const url = this.props.url;

        var print_btn;

        if (this.state.data.id) {
            print_btn = (<div className="btn-group">
                <button data-toggle="dropdown" className="btn btn-warning dropdown-toggle mr-2" aria-expanded="true">พิมพ์</button>
                <ul className="dropdown-menu" x-placement="bottom-start">
                    <li><a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={this.state.report_car_link}>ใบคำขอใช้รถ</a></li>
                </ul>
            </div>);
        }

        const lovEmployeeColumns = [
            { label: 'ชื่อ', name: 'name', className: ['text-left'] },
            { label: 'เบอร์โทร', name: 'telephone', className: ['text-left'] },
            { label: 'รถร่วม', name: 'outsource', className: ['text-center'] }
        ];

        const employees = this.state.data.employees.map((item, index) => {
            return (
                <tr key={index}>
                    <td>
                        {item.value.name}
                    </td>
                    <td>
                        {item.value.telephone}
                    </td>
                    <td className='text-center'>
                        {
                            item.value.is_outsource ?
                                <FormInput field={{ name: 'car_text', value: item.value.car_text }} getValue={(event) => this.getValue(event, index)}></FormInput>
                                :
                                <FormInput field={{ value: item.value.car_id, name: "car_id", type: "ddl", datasource: this.state.datasource.car_datasource }} onChange={(event) => this.onChangeCar(event.target, index)}></FormInput>

                        }
                    </td>
                    <td className='text-center'>
                        {item.value.outsource}
                    </td>
                    <td className='text-center'>
                        <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-sm ml-1" onClick={() => this.deleteEmployee(index)}>
                            <i className="fa fa-trash"></i> ลบ
                        </button>
                    </td>
                </tr>
            )
        });

        let paid;

        if (this.state.data.payment === true) {
            paid = (<div className='alert alert-info'>ชำระค่าบริการแล้ว</div>)
        }
        else if (this.state.data.invoice !== undefined && this.state.data.invoice !== null) {
            const invoice = this.state.data.invoice;
            const invoice_no = invoice.invoice_no;
            const link_to_invoice = ('/transaction/invoice/' + invoice.id + '/edit');

            paid = (<div className='alert alert-info'>ออกใบแจ้งหนี้เลขที่ <u><a target='_blank' href={link_to_invoice}>{invoice_no}</a></u> <i className='fa fa-external-link'></i></div>)
        }

        const payment_btn = (this.state.payment_btn === true ? <button className="btn btn-success mr-2" type="button" onClick={this.paymentClick}>ชำระค่าบริการแล้ว</button> : '');

        return (
            <BoxContainer loading={this.state.loading} errors={this.state.errors}>
                {redirect}
                {paid}
                <form onSubmit={this.onSubmit} noValidate>
                    <div className="form-group row">
                        <div className="col-12 text-right">
                            {payment_btn}
                            {print_btn}
                            <button className="btn btn-primary mr-2" type="submit">บันทึก</button>
                            <Link className="btn btn-white" to={url}>ย้อนกลับ</Link>
                        </div>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group">
                        <label className="col-form-label">ลิงค์สำหรับแชร์ใบงาน:</label>
                        <span id='copytext'>{this.state.data.shareable_report_link}</span>
                        <button type="button" className="ml-2 btn-sm btn btn-primary" ref={this.btnCopyElement} data-clipboard-target="#copytext"><i className="fa fa-copy"></i> คัดลอก</button>
                    </div>
                    <div className="form-group">
                        <label className="col-form-label">เลขที่ใบงาน:</label> {this.state.data.document_no || '-'}
                    </div>
                    <FormInput field={{ value: this.state.data.customer_id, name: "customer_id", label: "ลูกค้า", type: "ddl", datasource: this.state.datasource.customer_datasource, required: true }} onChange={this.onChange}></FormInput>
                    <FormInput field={{ value: this.state.data.contact_customer_id, name: "contact_customer_id", label: "ผู้ติดต่อ", type: "ddl", datasource: this.state.datasource.contact_customer_datasource, allow_new_item: true }} onChange={this.onChange}></FormInput>
                    {/* <FormInput field={{ value: this.state.data.province_id, name: "province_id", label: "จังหวัด", type: "ddl", datasource: this.state.datasource.province_datasource }} onChange={this.onChange}></FormInput> */}
                    <div className="form-group">
                        <label className="col-form-label">เลขที่ใบคำขอใช้รถ:</label> <input type="text" className="form-control" name="job_ref_no" value={this.state.data.job_ref_no} onChange={this.onChange} />
                    </div>
                    <div className="form-group">
                        <label className="col-form-label">ผู้ใช้รถ:</label> <input type="text" className="form-control" name="requester" value={this.state.data.requester} onChange={this.onChange} />
                    </div>
                    <div className='form-group'>
                        <label className="col-form-label">พนักงานขับรถ:</label>
                        <div className='text-right'><LOV button_text="เพิ่มข้อมูลพนักงาน" is_button={true} name='employee' title='ข้อมูลพนักงาน' api={this.state.employee_api} columns={lovEmployeeColumns} onChange={this.onEmpolyeeChange}></LOV></div>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>ชื่อ</th>
                                    <th>เบอร์โทร</th>
                                    <th>ทะเบียนรถ</th>
                                    <th>รถร่วม</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employees}
                            </tbody>
                        </table>
                    </div>
                    <JobDetailForm employees={this.state.data.employees} value={this.state.data.job_details} getValue={this.getValueJobDetail} expense_datasource={this.state.datasource.expense_datasource} province_datasource={this.state.datasource.province_datasource}></JobDetailForm>
                    <div className='form-group'>
                        <label className="col-form-label">หมายเหตุ:</label> <textarea className="form-control" name="remark" onChange={this.onChange} value={this.state.data.remark}></textarea>
                    </div>
                    <div className="hr-line-dashed"></div>
                    <div className="form-group row">
                        <div className="col-12 text-right">
                            {payment_btn}
                            {print_btn}
                            <button className="btn btn-primary mr-2" type="submit">บันทึก</button>
                            <Link className="btn btn-white" to={url}>ย้อนกลับ</Link>
                        </div>
                    </div>
                </form >
            </BoxContainer>
        )
    }
}

export default JobForm
