import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import ReceiptForm from './ReceiptForm';
import TitleHeader from '../../../components/TitleHeader/TitleHeader'

import axios from '../../../axios';
class Receipt extends Component {
    onPrintClick = item => {
        const url = (axios.defaults.baseURL + '/report/receipt/' + item.key);
        window.open(url, '_target')
    }
    render() {
        const columns = [
            { label: 'เลขที่', align: 'center', name: 'receipt_no', search_type: 'string' },
            { label: 'ลูกค้า', align: 'left', name: 'customers.name', search_type: 'string' },
            { label: 'ใบแจ้งหนี้', align: 'left', name: 'invoice', sortable: false },
        ];

        const api_url = '/transaction/receipt';

        return (
            <BrowserRouter>
                <div>
                    <TitleHeader program_name='ข้อมูลใบเสร็จรับเงิน' program_type='Transaction'></TitleHeader>

                    <Route exact path={`${this.props.match.path}/`} component={() => (<DataTable
                        columns={columns}
                        url={api_url}
                        allow_print={true}
                        onPrintClick={this.onPrintClick}></DataTable>)} />

                    <Route path={`${this.props.match.path}/create`} component={props => (<ReceiptForm {...props} url={api_url}></ReceiptForm>)} />
                    <Route path={`${this.props.match.path}/:id/edit`} component={props => (<ReceiptForm {...props} url={api_url}></ReceiptForm>)} />
                </div>
            </BrowserRouter>
        )
    }
}

export default Receipt
