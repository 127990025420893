import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import FormInput from '../../../components/DataForm/FormInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import axios from '../../../axios';
import JobReport from './JobReport';
import DataTable from '../../../components/DataTable/DataTable';

class JobIndex extends Component {
    state = {
        columns: [
            { label: 'เลขที่', align: 'center', name: 'document_no', search_type: 'string' },
            { label: 'ลูกค้า', align: 'left', name: 'customers.name', search_type: 'string' },
            { label: 'วันที่', align: 'center', name: 'transaction_date', search_type: 'datepicker', sortable: false },
            { label: 'พนักงาน', align: 'left', name: 'employees.name', sortable: false },
        ],
        checkbox_list: [],
    }

    onPrintClick = item => {
        const url = (axios.defaults.baseURL + '/report/car/' + item.key);
        window.open(url, '_target')
    }

    onCheckClick = key => {
        const checkbox_list = this.state.checkbox_list;

        const index = checkbox_list.indexOf(key);
        if(index === -1){
            checkbox_list.push(key);
        }else{
            checkbox_list.splice(index, 1);
        }

        this.setState({ checkbox_list });
    }

    getButtons = () => {
        return (
            <JobReport list_id={this.state.checkbox_list}></JobReport>
        );
    }

    render() {
        const buttons = this.getButtons();

        return (
            <DataTable 
                columns={this.state.columns} 
                url={this.props.api_url} 
                allow_print={true} 
                onPrintClick={this.onPrintClick}
                buttons={buttons}
                allow_checkbox={true}
                onCheckClick={this.onCheckClick}
            >
            </DataTable>    
        )
    }
}

JobIndex.defaultProps = {
    api_url: '',
};

export default JobIndex