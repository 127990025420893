import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import axios from '../../axios';
import Switcher from './../Switcher/Switcher';

var HtmlToReactParser = require('html-to-react').Parser;

class SearchResult extends Component {
    state = {
        items_selected: [],
        order_by: []
    }

    deleteHandler = (del) => {
        swal({
            title: "Delete",
            text: "ต้องการลบข้อมูลนี้หรือไม่",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(this.props.url + '/' + del, { data: { id: del } }).then(res => {
                    if (res.data.status) {
                        swal({
                            title: "Success",
                            text: "ข้อมูลถูกลบ",
                            icon: "success",
                            button: "OK",
                        }).then(() => {
                            this.props.search();
                        });
                    } else {
                        let error_msg = "ไม่สามารถลบข้อมูลนี้ได้";
                        if (res.data.message) {
                            error_msg = res.data.message;
                        }

                        swal({
                            title: "Eror",
                            text: error_msg,
                            icon: "error",
                            button: "OK",
                        });
                    }
                });
            }
        });
    }

    toggle_selection = e => {
        let checked = e.target.checked;
        let items_selected = [];
        if (checked) {
            items_selected = this.props.data.map(item => { return item.key });
        }

        this.setState({ items_selected });
    }

    toggle_selection_child = (e, key) => {
        const checked = e.target.checked;
        let items_selected = this.state.items_selected;
        const index = items_selected.indexOf(key);

        if (checked === true && index === -1) {
            items_selected.push(key);
        } else if (checked === false && index !== -1) {
            items_selected.splice(index, 1);
        }

        this.setState({ items_selected });
    }

    order_by = item => {
        if (item.sortable === false) return;

        let order_by = [];

        if (this.state.order_by.length > 0 && this.state.order_by[0] === item.name) {
            if (this.state.order_by[1] === 'desc') {
                order_by = [item.name, 'asc'];
            } else {
                order_by = [item.name, 'desc'];
            }
        }
        else {
            order_by = [item.name, 'asc'];
        }

        this.setState({ order_by });

        const params = { order_by: order_by.join(',') };

        this.props.search(params);
    }

    SwitchHandler = (value, key) => {
        const update_url = (`${this.props.url}/${key}`);
        //update active
        const params = {
            flag: 'active',
            id: key,
            ...value
        }

        axios.put(update_url, params).then(res => {
            // do something
            window.toastr.options = {
                "closeButton": true,
                "debug": false,
                "progressBar": true,
                "preventDuplicates": false,
                "positionClass": "toast-top-right",
                "onclick": null,
                "showDuration": "400",
                "hideDuration": "1000",
                "timeOut": "7000",
                "extendedTimeOut": "1000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            }

            window.toastr.success('ข้อมูลถูกอัพเดทเรียบร้อย', 'Update complete!');
        }).catch(err => {
            let error_msg = err.response.data.message;
            if (error_msg === undefined || error_msg === '') {
                error_msg = "ไม่สามารถ Inactive ข้อมูลนี้ได้";
            }

            swal({
                title: "Eror",
                text: error_msg,
                icon: "error",
                button: "OK",
            });
        });
    }

    onCheckboxChange = key => {
        this.props.onCheckClick(key);
    }

    render() {
        const path = window.location.pathname;
        const { allow_insert, allow_edit, allow_trash, allow_print, allow_action } = this.props;
        const allow_action_control = allow_action && (allow_insert || allow_edit || allow_trash || allow_print);

        var col_headers = this.props.columns.map((item, index) => {
            const class_name = ['text-center'];

            if (item.sortable === undefined || item.sortable === true) {
                class_name.push('sortable');
            }

            if (this.state.order_by.length > 0 && this.state.order_by[0] === item.name) {
                class_name.push(this.state.order_by[1]);
            }

            return (
                <th className={class_name.join(' ')} key={index} onClick={() => this.order_by(item)}>
                    {item.label}
                </th>
            )
        })

        // var col_header_checkbox = <th key={-1} className="text-center"><div className="checkbox m-r-xs"><input type="checkbox" className='checkbox-head' onClick={this.toggle_selection} /></div></th>;
        var col_header_checkbox = <th key={-1} className="text-center">#</th>;
        var col_header_action = <th key={-3} className='text-center'># Action</th>

        col_headers.unshift(col_header_checkbox);

        if (this.props.allow_active === true) {
            var col_header_active = <th key={-2} className='text-center'># Active</th>
            col_headers.push(col_header_active);
        }

        if (allow_action_control === true) {
            col_headers.push(col_header_action);
        }

        var row_data;

        if (this.props.loading) {
            row_data = <tr><td colSpan={col_headers.length} className="text-center">Loading ...</td></tr>
        } else {
            const { records_per_page, page_number } = this.props.criteria;

            var running = ((records_per_page * page_number) - records_per_page) + 1;

            row_data = this.props.data.map(item => {
                const key = item.key;

                var col_data = item.value.map((sub_item, sub_index) => {
                    var align_class = "text-left";
                    var align = this.props.columns[sub_index] !== undefined ? this.props.columns[sub_index].align : null;

                    if (align === 'center') {
                        align_class = 'text-center';
                    }
                    else if (align === 'right') {
                        align_class = 'text-right';
                    }

                    var class_name = [align_class];

                    var htmlInput = sub_item;
                    var htmlToReactParser = new HtmlToReactParser();
                    var reactElement = htmlToReactParser.parse(htmlInput);

                    return (
                        <td key={sub_index} className={class_name.join(' ')}>{reactElement}</td>
                    );
                })

                
                if (this.props.allow_active === true) {
                    var col_data_active = (<td key={-2} className="text-center"><Switcher name='active' value={item.active} onChange={value => this.SwitchHandler(value, key)}></Switcher></td>)
                    col_data.push(col_data_active);
                }


                if (allow_action_control === true) {
                    var print_btn;
                    var edit_btn;
                    var trash_btn;

                    if (allow_print === true) {
                        print_btn = <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Print" title="Print" className="btn btn-warning btn-sm mr-1" onClick={() => this.props.onPrintClick(item)}>
                            <i className="fa fa-print"></i> พิมพ์
                            </button>
                    }

                    if (allow_edit === true) {
                        edit_btn = (<span>
                            <Link data-toggle="tooltip" data-placemeent="top" data-original-title="Edit" title="Edit" to={`${path}/${key}/edit`} className="btn btn-secondary btn-sm">
                                <i className="fa fa-pencil"></i> แก้ไข
                                        </Link>
                        </span>)
                    }

                    if (allow_trash === true) {
                        trash_btn = (
                            <button type="button" data-toggle="tooltip" data-placemeent="top" data-original-title="Trash" title="Trash" className="btn btn-danger btn-sm ml-1" onClick={() => this.deleteHandler(key)}>
                                <i className="fa fa-trash"></i> ลบ
                            </button>
                        )
                    }

                    var col_data_action = <td key={-1} className="text-center">
                        <div className="btn-group">
                            {print_btn}
                            {edit_btn}
                            {trash_btn}
                        </div>
                    </td>;

                    col_data.push(col_data_action);
                }

                // var checked = (this.state.items_selected.indexOf(key) !== -1);
                // var col_data_checkbox = (<td key={-3} className="text-center">
                //     <div className="checkbox m-r-xs">
                //         <input type="checkbox" className='checkbox-child' checked={checked} onChange={e => this.toggle_selection_child(e, key)} />
                //     </div>
                // </td>);
                var col_data_checkbox;

                if(this.props.allow_checkbox === true){
                    col_data_checkbox = (<td key={-3} className="text-center"><input type='checkbox' onChange={ e => this.onCheckboxChange(item.key) } /></td>);
                }else{
                    col_data_checkbox = (<td key={-3} className="text-center">{running++}</td>);
                }

                col_data.unshift(col_data_checkbox);

                const class_row = [];

                if (item.active === false) {
                    class_row.push('bg-danger text-white');
                }

                return (
                    <tr key={key} className={class_row.join(' ')}>
                        {col_data}
                    </tr>
                )
            })
        }

        return (
            <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover sortable" >
                    <thead>
                        <tr className="table-header">{col_headers}</tr>
                    </thead>
                    <tbody>
                        {row_data}
                    </tbody>
                    <tfoot>
                        <tr>{col_headers}</tr>
                    </tfoot>
                </table>
            </div>
        )
    }
}

SearchResult.defaultProps = {
    allow_active: false,
    criteria: {},
    allow_print: false,
    onPrintClick: [],
    allow_insert: true,
    allow_edit: true,
    allow_trash: true,
    allow_action: true,
    allow_checkbox: false,
    onCheckClick: [],
}

export default SearchResult
