import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import FormInput from './../../../components/DataForm/FormInput';
import axios from '../../../axios';
import { Services } from '../../Service/Services';
import { Redirect } from 'react-router-dom';
import BoxContainer from '../../../components/BoxContainer/BoxContainer';
const $ = window.$;

class ReceiptForm extends Component {
    state = {
        customer_datasource: [],
        bank_datasource: [],
        invoices: [],
        url: '/transaction/receipt',
        get_receipt: 'receipt',
        select_invoices: [],
        customer_id: '',
        money_type: 'T',
        receipt_no: '-',
        invoice_type: 'N',
        customer_name: '',
        remark: '',
        report_link: axios.defaults.baseURL + '/report/receipt/' + this.props.match.params.id,
        report_bill_link: axios.defaults.baseURL + '/report/bill/' + this.props.match.params.id,
        id: '',

        bank_id: 0,
        datasource: {
            payment_method_datasource: [
                { key: 'T', label: 'เงินโอน(Transfer)' },
                { key: 'M', label: 'เงินสด(Cash)' },
                { key: 'C', label: 'เช็ค(Cheque)' },
            ],
            invoice_type_datasource: [
                { key: 'N', label: 'ใบแจ้งหนี้ทั่วไป' },
                { key: 'D', label: 'ใบแจ้งหนี้ค่าบริการรับ-ส่งเอกสาร' },
                { key: 'C', label: 'ใบแจ้งหนี้ค่าบริการรถตู้รับ-ส่ง' },
            ],
        },
        loading: true,
    }
    retrieveData = () => {
        const id = this.props.match.params.id;

        if (id) {
            axios.get(this.state.url + '/' + id).then(res => {
                var total_price = 0;
                const invoices = res.data.invoice_detail.map((item, index) => {
                    total_price += item.price;

                    var defaultChecked = res.data.receipt_details.find(p => p.invoice_id === item.id && p.receipt_id === item.receipt_id) !== undefined ? true : false;
                    if (defaultChecked) {
                        this.setState({
                            select_invoices: [...this.state.select_invoices, { 'id': res.data.receipt_details.find(p => p.invoice_id === item.id && p.receipt_id === item.receipt_id).invoice_id }]
                        });
                    }

                    return (
                        <tr key={index}>
                            <td className='text-center'><input type="checkbox" className="" onChange={this.onSelectJobs(item)} defaultChecked={defaultChecked} /></td>
                            <td className='text-center'>{item.invoice_no}</td>
                            <td className='text-center'>{this.getInvoiceType(item.invoice_type)}</td>
                            <td className='text-center'>{Services.getThaiDateFullFormat(item.transaction_date.date)} </td>
                            <td className="text-right">{Services.convertToMoney(item.price)}</td>
                        </tr>
                    )
                });

                this.setState({
                    id: id,
                    customer_datasource: res.data.datasource.customers,
                    bank_datasource: res.data.datasource.banks,
                    invoices: invoices,
                    invoice_type: res.data.receipt.invoice_type,
                    total_price: Services.convertToMoney(total_price),
                    customer_id: res.data.customers_default.id,
                    customer_name: res.data.customers_default.name,
                    receipt_no: res.data.receipt.receipt_no,
                    transaction_date: Services.convertDate(res.data.receipt.transaction_date),
                    bank_id: res.data.receipt.bank_id,
                    money_type: res.data.receipt.money_type,
                    transfer: res.data.receipt.money_type === 'T' ? true : false,
                    cash: res.data.receipt.money_type === 'M' ? true : false,
                    cheque: res.data.receipt.money_type === 'C' ? true : false,
                    loading: false,
                    remark: res.data.receipt.remark,
                });
            });
        } else {
            const today = new Date();
            const transaction_date = (today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear());

            const params = {
                tables: [
                    { name: 'customer' },
                    { name: 'bank' },
                ],
            }

            axios.post('/dropdown/datasource', params).then(res => {
                const customer_datasource = res.data.customer;
                let bank_datasource = res.data.bank;

                // bank_datasource.unshift({
                //     'key': 0,
                //     'label': 'ไม่มี',
                // });

                bank_datasource = bank_datasource.map(function (item, key) {
                    let img_path;

                    if (item.avatar) {
                        // img_path = require('~/assets' + item.avatar);
                        // <img alt="Bank" src={img_path} className="img-bank UOB" />
                    }

                    const label = (
                        <React.Fragment key={key}>
                            {item.label}
                        </React.Fragment>
                    );

                    return {
                        key: item.key,
                        label: label,
                    }
                });

                this.setState({
                    customer_datasource: customer_datasource,
                    bank_datasource: bank_datasource,
                    loading: false,
                    transaction_date,
                });
            });
        }
    }

    onSubmit = e => {
        e.preventDefault();
        const id = this.props.match.params.id;
        const url = this.props.url;
        const params = {
            customer_id: this.state.customer_id,
            invoices: this.state.select_invoices,
            transaction_date: this.state.transaction_date,
            bank_id: this.state.bank_id,
            invoice_type: this.state.invoice_type,
            money_type: this.state.money_type,
            id: id,
            remark: this.state.remark,
        }

        const redirect = true;

        let loading = true;

        this.setState({ loading });

        Services.submitRequest({ params, url }).then(res => {
            if (id) {
                this.retrieveData(id);
            } else {
                const data = {
                    ...this.state.data,
                    id: res.data.id
                };

                loading = false;

                this.setState({ redirect, loading, data });
            }
        }).catch(errors => { this.setState({ errors, loading }) });
    }
    componentDidMount() {
        this.retrieveData();
    }
    onSelectJobs = value => event => {
        if (event.target.checked) {
            this.setState({
                select_invoices: [...this.state.select_invoices, { 'id': value.id }]
            });
        }
        else {
            this.setState({
                select_invoices: this.state.select_invoices.filter(function (select_invoices) {
                    return select_invoices.id !== value.id
                })
            });
        }
    }
    onChange = e => {
        // if (e.target.name === 'bank') {
        //     axios.get('/bank/' + e.target.value).then(res => {
        //         $("#bank").autocomplete({
        //             source: res.data.bank
        //         });
        //     });
        //     this.setState({ bank: e.target.value });
        // } else {
        const value = e.target.value;
        this.setState({ customer_id: value });
        this.getReceipt(this.state.invoice_type);
        // }
    }

    getInvoiceType = invoice_type => {
        if (invoice_type) {
            var invoice_type_value = this.state.datasource.invoice_type_datasource.filter(f => f.key === invoice_type)[0].label;

            return invoice_type_value;
        }

        return '';
    }

    getReceipt = (invoice_type) => {
        const params = {
            invoice_type: invoice_type,
            customer_id: this.state.customer_id,
            receipt_id: this.state.id,
        };

        if (params.customer_id !== '') {
            axios.post(this.state.get_receipt, params).then(res => {
                var total_price = 0;
                const invoices = res.data.invoice_detail.map((item, index) => {

                    total_price += item.price;

                    console.log(item);

                    return (
                        <tr key={index}>
                            <td className='text-center'><input type="checkbox" className="" onChange={this.onSelectJobs(item)} /></td>
                            <td className='text-center'>{item.invoice_no}</td>
                            <td className='text-center'>{this.getInvoiceType(item.invoice_type)}</td>
                            <td className='text-center'>{Services.getThaiDateFullFormat(item.transaction_date.date)} </td>
                            <td className="text-right">{Services.convertToMoney(item.price)}</td>
                        </tr>
                    )
                });
                this.setState({
                    // customer_datasource: res.data.customers.map(item => { return { key: item.id, label: item.branch ? item.name + ' - สำนักงานใหญ่' : item.name + ' - สาขาที่ ' + item.branch_text } }),
                    invoices: invoices,
                    total_price: Services.convertToMoney(total_price),
                });
            });
        }
    }

    datepickerCallback = (value) => { this.setState(value); }

    getValue = e => {
        const name = Object.keys(e)[0];
        this.setState(e);

        if (name === 'invoice_type') {
            const value = Object.values(e)[0];
            this.getReceipt(value);
        }
    }

    getRedirect = () => {
        const redirect = this.state.redirect;

        if (redirect) {
            const url = ('/transaction/receipt/' + this.state.data.id + '/edit');

            return <Redirect to={url} />;
        }

        return;
    }

    getFields = () => {
        const id = this.props.match.params.id;

        var bank_field = <FormInput field={{ name: "bank_id", label: "ธนาคาร", type: "radio", datasource: this.state.bank_datasource, value: this.state.bank_id, required: false }} getValue={this.getValue}></FormInput>
        var customer_field;
        var invoice_type_field;
        var remark;
        if (id) {
            var invoice_type_value = this.state.datasource.invoice_type_datasource.filter(f => f.key == this.state.invoice_type)[0].label;

            customer_field = <FormInput field={{ name: "customer_name", label: "ลูกค้า", type: "label", value: this.state.customer_name }}></FormInput>
            invoice_type_field = <FormInput field={{ name: "invoice_type", label: "ประเภทใบแจ้งหนี้", type: "label", value: invoice_type_value }}></FormInput>

            remark = <FormInput field={{ name: "remark", label: "หมายเหตุ", type: "textarea", value: this.state.remark }} getValue={this.getValue}></FormInput>
        } else {
            customer_field = <FormInput field={{ name: "customer", label: "ลูกค้า", type: "ddl", datasource: this.state.customer_datasource, value: this.state.customer_id, required: true }} onChange={this.onChange}></FormInput>
            invoice_type_field = <FormInput field={{ name: "invoice_type", label: "ประเภทใบแจ้งหนี้", type: "radio", datasource: this.state.datasource.invoice_type_datasource, value: this.state.invoice_type, required: true }} getValue={this.getValue}></FormInput>

            remark = <FormInput field={{ name: "remark", label: "หมายเหตุ", type: "textarea", value: this.state.remark }} getValue={this.getValue}></FormInput>
        }

        return (
            <div>
                {customer_field}
                <FormInput field={{ name: "transaction_date", label: "วันที่ออกใบเสร็จ", type: "datepicker", required: true, value: this.state.transaction_date }} datepickerCallback={this.datepickerCallback}></FormInput>
                <FormInput field={{ name: "money_type", label: "ประเภทการจ่าย", type: "radio", value: this.state.money_type, datasource: this.state.datasource.payment_method_datasource }} getValue={this.getValue}></FormInput>
                {invoice_type_field}
                {bank_field}
                {remark}
            </div>
        )
    }

    setRemark = text => {
        this.setState((state) => ({ 
            remark: text,
        }));
    }

    render() {
        const redirect = this.getRedirect();
        const fields = this.getFields();
        const url = this.props.url;

        const id = this.props.match.params.id;
        var report_button;

        if (id) {
            let receipt_button = (<li key='1'><a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={this.state.report_link}>ใบเสร็จรับเงิน</a></li>);
            let bill_button;
            
            if(this.state.invoice_type === 'N'){
                bill_button = (<li key='2'><a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={this.state.report_bill_link}>ใบวางบิล</a></li>);
            }
            
            report_button = <div className="btn-group">
                <button data-toggle="dropdown" className="btn btn-warning dropdown-toggle mr-2" aria-expanded="true">พิมพ์</button>
                <ul className="dropdown-menu" x-placement="bottom-start">
                    { receipt_button }
                    { bill_button }
                </ul>
            </div>
        }

        return (
            <BoxContainer loading={this.state.loading} errors={this.state.errors}>
                {redirect}
                <form onSubmit={this.onSubmit}>
                    <div className="form-group row">
                        <div className="col-12 text-right">
                            {report_button}
                            <button className="btn btn-primary mr-2" type="submit">บันทึก</button>
                            <Link className="btn btn-white" to={this.state.url}>ย้อนกลับ</Link>
                        </div>
                    </div>
                    <div className="row wrapper wrapper-content animated fadeInRight">
                        <div className='col-12'>
                            <div className='ibox'>
                                <div className='ibox-content'>
                                    <div className="form-group">
                                        <label className="col-form-label">เลขที่ใบเสร็จรับเงิน:</label> {this.state.receipt_no}
                                    </div>

                                    {fields}
                                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-secondary" onClick={() => this.setRemark('ค่าบริการหักภาษี 3%')}>ค่าบริการหักภาษี 3%</button>
                                    </div>


                                    <div className="form-group">
                                        <label className="col-form-label">รายละเอียด:</label>
                                        <table className="table checkable">
                                            <thead>
                                                <tr>
                                                    <th><input type="checkbox" className="" /></th>
                                                    <th>เลขที่ใบแจ้งหนี้</th>
                                                    <th>ประเภทใบแจ้งหนี้</th>
                                                    <th>วันที่ออกใบแจ้งหนี้</th>
                                                    <th>ราคา</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.invoices}
                                            </tbody>
                                            <tfoot>
                                                <tr className="bg-secondary text-white">
                                                    <td className="text-right" colSpan="4">รวม</td>
                                                    <td className="text-right">{this.state.total_price}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>

                                    <div className="hr-line-dashed"></div>
                                    <div className="form-group row">
                                        <div className="col-12 text-right">
                                            {report_button}
                                            <button className="btn btn-primary mr-2" type="submit">บันทึก</button>
                                            <Link className="btn btn-white" to={this.state.url}>ย้อนกลับ</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </BoxContainer>
        )
    }
}

export default ReceiptForm
